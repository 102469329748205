import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Home Pages Import
import DigitalAgency from "./pages/DigitalAgency";

// Blog Import
import BlogGridView from "./pages/BlogGrid";
import BlogCategory from "./pages/Category";
import BlogArchive from "./pages/Archive";
import BlogDetails from "./pages/BlogDetails";

// Service
import ServiceOne from "./pages/ServiceOne";
import ServiceDetails from "./pages/ServiceDetails";

// Project
import ProjectGridTwo from "./pages/ProjectGridTwo";
import ProjectGridThree from "./pages/ProjectGridThree";
import ProjectDetails from "./pages/ProjectDetails";

// Pages
import AboutUs from "./pages/AboutUs";
import VisiterManageSystem from "./pages/VisiterManageSystem";
import TeamDetails from "./pages/TeamDetails";
import CaseStudy from "./pages/CaseStudy";
import CaseDetails from "./pages/CaseStudyDetails";
import Testimonials from "./pages/Testimonials";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/404";
import ComingSoon from "./pages/ComingSoon";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

// Css Import
import "./assets/scss/app.scss";
import Consultancy from "./pages/Consultancy";
import StaffAugmentation from "./pages/Staff-Augmentation";
import Career from "./pages/Career";
import FaqOne from "./component/faq/FaqOne";
import WebDevelopment from "./pages/WebDevelopment";
import MobileAppDevelopment from "./pages/MobileAppDevelopment";
import Design from "./pages/Design";
import { Helmet } from "react-helmet";

const App = () => {
  console.log("yes--->");
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
      // "@context": "https://schema.org/", 
      // "@type": "BreadcrumbList", 
      // "itemListElement": [{
      //   "@type": "ListItem", 
      //   "position": 1, 
      //   "name": "Service",
      //   "item": "https://itcodehelp.com/service"  
      // },{
      //   "@type": "ListItem", 
      //   "position": 2, 
      //   "name": "Consultancy",
      //   "item": "https://technicalseo.com/tools/schema-markup-generator/"  
      // },{
      //   "@type": "ListItem", 
      //   "position": 3, 
      //   "name": "Staff Augmentation",
      //   "item": "https://itcodehelp.com/Staff-Augmentation"  
      // },{
      //   "@type": "ListItem", 
      //   "position": 4, 
      //   "name": "Contact",
      //   "item": "https://itcodehelp.com/contact"  
      // }]
      {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "WebPage",
            "@id": "https://itcodeinfotech.com/",
            "url": "https://itcodeinfotech.com/",
            "name": "Trust ITCODE InfoTech for Your Custom Web & Mobile App Development Services USA",
            "isPartOf": {
              "@id": "https://itcodeinfotech.com/#website"
            },
            "about": {
              "@id": "https://itcodeinfotech.com/#organization"
            },
            "datePublished": "2022-09-20T11:49:53+00:00",
            "dateModified": "2023-02-08T18:02:20+00:00",
            "description": "Looking for the custom software development company? We offer customized web and mobile app development services to our clients leveraging skilled resources.",
            "breadcrumb": {
              "@id": "https://itcodeinfotech.com/#breadcrumb"
            },
            "inLanguage": "en-US",
            "potentialAction": [
              {
                "@type": "ReadAction",
                "target": [
                  "https://itcodeinfotech.com/"
                ]
              }
            ]
          },
          {
            "@type": "BreadcrumbList",
            "@id": "https://itcodeinfotech.com/#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home"
              }
            ]
          },
          {
            "@type": "WebSite",
            "@id": "https://itcodeinfotech.com/#website",
            "url": "https://itcodeinfotech.com/",
            "name": "ITCODE InfoTech",
            "description": "",
            "publisher": {
              "@id": "https://itcodeinfotech.com/#organization"
            },
            "potentialAction": [
              {
                "@type": "SearchAction",
                "target": {
                  "@type": "EntryPoint",
                  "urlTemplate": "https://itcodeinfotech.com/?s={search_term_string}"
                },
                "query-input": "required name=search_term_string"
              }
            ],
            "inLanguage": "en-US"
          },
          {
            "@type": "Organization",
            "@id": "https://itcodeinfotech.com/#organization",
            "name": "ITCODE InfoTech",
            "url": "https://itcodeinfotech.com/",
            "logo": {
              "@type": "ImageObject",
              "inLanguage": "en-US",
              "@id": "https://itcodeinfotech.com/#/schema/logo/image/",
              "url": "https://itcodeinfotech.com/static/media/logo.4289e6f7.svg",
              "contentUrl": "https://itcodeinfotech.com/static/media/logo.4289e6f7.svg",
              "width": 173,
              "height": 50,
              "caption": "ITCODE InfoTech"
            },
            "image": {
              "@id": "https://itcodeinfotech.com/static/media/logo.4289e6f7.svg"
            },
            "sameAs": [
              "https://www.linkedin.com/company/itcodehelp/mycompany/",
              "https://www.instagram.com/itcode_infotech/",
            ]
          }
        ]
      }
      `}
        </script>
      </Helmet>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<DigitalAgency />}
            />

            {/* Blogs */}
            <Route
              path={process.env.PUBLIC_URL + "/blog-grid/"}
              element={<BlogGridView />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/category/:slug"}
              element={<BlogCategory />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/archive/:slug"}
              element={<BlogArchive />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/blog-details/:id"}
              element={<BlogDetails />}
            />

            {/* Service */}
            <Route
              path={process.env.PUBLIC_URL + "/service/"}
              element={<ServiceOne />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/service-details/:slug"}
              element={<ServiceDetails />}
            />

            {/* Project  */}
            <Route
              path={process.env.PUBLIC_URL + "/project-grid-two"}
              element={<ProjectGridTwo />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/portfolio"}
              element={<ProjectGridThree />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/project-details/:slug"}
              element={<ProjectDetails />}
            />

            {/* Pages  */}
            <Route
              path={process.env.PUBLIC_URL + "/about-us"}
              element={<AboutUs />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/visitor-management-system"}
              element={<VisiterManageSystem />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/career"}
              element={<Career />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/team-details/:slug"}
              element={<TeamDetails />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/case-study"}
              element={<CaseStudy />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/case-details/:slug"}
              element={<CaseDetails />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/testimonials"}
              element={<Testimonials />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              element={<Contact />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/consultancy"}
              element={<Consultancy />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/Staff-Augmentation"}
              element={<StaffAugmentation />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/web-development"}
              element={<WebDevelopment />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/mobile-app-development"}
              element={<MobileAppDevelopment />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/ui-ux-design"}
              element={<Design />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/404"}
              element={<ErrorPage />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/coming-soon"}
              element={<ComingSoon />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/privacy-policy"}
              element={<PrivacyPolicy />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/terms-use"}
              element={<TermsOfUse />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/faqs"}
              element={<FaqOne />}
            />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
};

export default App;
