import React from "react";
import { Link, useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import { slugify } from "../utils";
// import ProjectData from "../data/project/ProjectData.json";
import VideoOne from "../component/video/VideoOne";
import Accordion from "react-bootstrap/Accordion";
import { FaCompress, FaCode, FaGlobe, FaPlay } from "react-icons/fa";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import Slider from "react-slick";
import { ProjectData } from "../component/project/ProjectData";
const allProjectData = ProjectData;

const ProjectDetails = () => {
  const params = useParams();
  const projectSlug = params.slug;

  const getProjectData = allProjectData.find(
    (data) => slugify(data.id) === projectSlug
  );
  // const detailsProject = getProjectData[0];

  var slideSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <SEO title="Project Details" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        {/* <BcrumbBannerTwo 
            title={detailsProject.title}
            paragraph ={detailsProject.excerpt}
            mainThumb={detailsProject.image}
            /> */}
        {/* <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">
                                    {detailsProject.category.map((cat, i) => (
                                            <span key={i}>{cat}</span> 
                                        )
                                    )}
                                </span>
                                <h3 className="title">{detailsProject.title}</h3>
                            </div>
                            {detailsProject.body.map((para, index) => (
                                <p key={index} dangerouslySetInnerHTML={{__html: para}}></p>
                            ))}
                            <Link to="#" className="axil-btn btn-fill-primary">Get it Now</Link>
                        </div>
                        <div className="col-lg-6 offset-xl-1">
                            <div className="why-choose-us">
                                <div className="section-heading heading-left">
                                    <h3 className="title">We delivered</h3>
                                    <p>Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business.</p>
                                </div>
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><FaCompress /> Strategy</Accordion.Header>
                                        <Accordion.Body>
                                        Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim gravida pellentesque.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><FaCode /> Design</Accordion.Header>
                                        <Accordion.Body>
                                        Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim gravida pellentesque.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><FaGlobe /> Development</Accordion.Header>
                                        <Accordion.Body>
                                        Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim gravida pellentesque.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        {/* <VideoOne /> */}
        <div className="breadcrum-area">
          <div className="section">
            <div className="container">
              <div className="about-expert">
                <div className="d-block ">
                  <Slider {...slideSettings} className="slick-dot-nav">
                    {getProjectData.image1.map((img, index) => (
                      <div className="thumbnail p-lg-5 p-3 shadow-none bg-white border d-flex justify-content-center">
                        <img
                          src={img}
                          alt={`Project Image ${index}`}
                          key={index}
                          style={{maxHeight: "500px" , borderRadius : "5px"}}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              <div class="section-padding pb-0">
                <h2 class="title">Project Details</h2>
                <p className="mb-0">
                  {getProjectData.body.map((text, index) => (
                    <span key={index} style={{ marginBottom: "1rem" }}>
                      {text}
                    </span>
                  ))}
                </p>
              </div>
            </div>

            <ul className="shape-group-17 list-unstyled">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                  alt="circle"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                  alt="Line"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                  alt="Line"
                />
              </li>
            </ul>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectDetails;
