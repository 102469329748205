import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li>
          <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
        </li>
        <li className="menu-item-has-children">
          <Link to="#">
            Services <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/service"}>
                IT Services & Solutions
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/consultancy"}>
                Consultancy
              </Link>
            </li>
            {/* <li><Link to={process.env.PUBLIC_URL + "/Staff-Augmentation"}>Staff-Augmentation</Link></li> */}
            {/* <li><Link to={process.env.PUBLIC_URL + "/service-two"}>Service Two</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/service-details/design"}>Service Details</Link></li> */}
          </ul>
        </li>
        <li className="menu-item-has-children">
          <Link to="#">
            Solutions <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/visitor-management-system"}>
                Visitor Management System
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/portfolio"}>Portfolio</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/career"}>Career</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/Staff-Augmentation"}>
            Hire Our Developer
          </Link>
        </li>
        {/* <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link></li> */}
        {/* <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li> */}
      </ul>
    </nav>
  );
};

export default Nav;
