import React, { useRef, useState } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ServiceProp from "../component/service/ServiceProp";
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from "../utils";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import { Link } from "react-scroll";
import { db, storage } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import seoData from "../seoData/Service.json";

const allData = ServiceData;

const ServiceOne = () => {
  const form = useRef();

  const [scroll, setScroll] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    resume: "",
    message: "",
  });
  const [result, showresult] = useState(false);
  const [loading, setLoading] = useState();
  const [obj, setObj] = useState();

  const sendEmail = async (e) => {
    e.preventDefault();
    console.log("Form ref ==>", form.current);
    console.log("Form data ==>", formData);

    const ref = collection(db, "messages");
    await addDoc(ref, {
      ...formData,
    });
    setFormData({
      name: "",
      mobile: "",
      email: "",
      resume: "",
      message: "",
    });
    setObj();
    showresult(true);

    //     emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //       form.current.reset();
    //       showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 10000);

  const uploadResume = (e) => {
    // setObj((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    // setobjEdit((prevState) => ({
    //   ...prevState,
    //   [e.target.name]: e.target.value,
    // }));
    setLoading(true);
    try {
      var storageRef = ref(storage, `images/${e.target.files[0].name}`);
      var uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log("======>>>>>>>>>==============>>>>>>>>>>>", url);
            setFormData({ ...formData, resume: url });
            setObj(e.target.files[0].name);
            if (url) {
              setLoading(false);
            } else {
              setLoading(false);
            }
          });
        }
      );
    } catch (error) {
      console.log("eee", error);
      setLoading(false);
    }
  };

  const designData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "design"
  );
  const developmentData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "development"
  );
  const marketingData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "online-marketing"
  );
  const businessData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "business"
  );
  const technologyData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "technology"
  );
  const strategyData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "content-strategy"
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 447);
    });
  }, []);

  const Result = () => {
    return (
      <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
      </Alert>
    );
  };

  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Services" page="Service" />
        <div className="container">
          <p className="mt-5 pt-5 z-10">
            Client satisfaction and quality work are our core objectives. We
            wish to provide the best to our clients. Have a look and see if we
            can address your needs from the range of our services.
          </p>
          <p>
            We provide end-to-end IT solutions here with quality and client
            satisfaction at par, all these at the competent prices.
          </p>
        </div>
        <div className="service-scroll-navigation-area">
          {/* Service Nav */}

          <nav
            id="onepagenav"
            className={`service-scroll-nav navbar ${
              scroll ? "onepagefixed" : ""
            }`}
          >
            <div className="container">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  {/* <a
                    className={`nav-link`}
                    href="#section1"
                  > */}
                  <Link activeClass="kushal-class" smooth spy to="section1">
                    Design
                  </Link>
                  {/* </a> */}
                </li>
                <li className="nav-item">
                  <Link activeClass="kushal-class" smooth spy to="section2">
                    Development
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#section3"> */}
                  <Link activeClass="kushal-class" smooth spy to="section3">
                    Online Marketing
                  </Link>
                  {/* </a> */}
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#section4"> */}
                  <Link activeClass="kushal-class" smooth spy to="section4">
                    Business
                  </Link>
                  {/* </a> */}
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#section5"> */}
                  <Link activeClass="kushal-class" smooth spy to="section5">
                    Technology
                  </Link>
                  {/* </a> */}
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#section6"> */}
                  <Link activeClass="kushal-class" smooth spy to="section6">
                    Content Strategy
                  </Link>
                  {/* </a> */}
                </li>
              </ul>
            </div>
          </nav>

          <div className="section section-padding" id="section1">
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Design"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={designData}
                />
              </div>
            </div>
          </div>

          <div className="section section-padding bg-color-light" id="section2">
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Development"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={developmentData}
                />
              </div>
            </div>
          </div>

          <div className="section section-padding" id="section3">
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Online Marketing"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={marketingData}
                />
              </div>
            </div>
          </div>

          <div className="section section-padding bg-color-light" id="section4">
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Business"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={businessData}
                />
              </div>
            </div>
          </div>

          <div className="section section-padding" id="section5">
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Technology"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={technologyData}
                />
              </div>
            </div>
          </div>

          <div className="section section-padding bg-color-light" id="section6">
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Content strategy"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-4 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={strategyData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-9 mx-auto">
                <div className="contact-form-box shadow-box mb--100">
                  <form
                    className="axil-contact-form"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="contact-name"
                        required
                        value={formData.name}
                        onChange={(e) => {
                          setFormData({ ...formData, name: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="contact-email"
                        required
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="contact-phone"
                        required
                        value={formData.mobile}
                        onChange={(e) => {
                          setFormData({ ...formData, mobile: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group mb--40">
                      <label>How can we help you?</label>
                      <textarea
                        className="form-control"
                        name="contact-message"
                        rows="4"
                        value={formData.message}
                        onChange={(e) => {
                          setFormData({ ...formData, message: e.target.value });
                        }}
                      ></textarea>
                    </div>
                    <div className="form-group mb--40">
                      <label>Drop your CV</label>
                      <div className="fileUpload">
                        <input
                          type="file"
                          className="form-control fileUpload-inp"
                          name="contact-file"
                          required
                          onChange={(e) => uploadResume(e)}
                        />
                        <div className="fileUpload-mes">
                          Drag & Drop Files Here
                          <span>&nbsp;Browse Files</span>
                        </div>
                      </div>
                      {obj && <p>{obj} file uploaded</p>}
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                        name="submit-btn"
                        disabled={loading}
                      >
                        {loading ? "Please wait" : "Submit"}
                      </button>
                    </div>
                    <div className="form-group">
                      {result ? <Result /> : null}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ServiceOne;
