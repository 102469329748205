import React from "react";
import { Link } from "react-router-dom";
import Tilty from 'react-tilty';

const AboutSeven = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <Tilty perspective={2000} reset={false}>
              <img
                src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"}
                alt="travel"
              />
              </Tilty>
              {/* <img
                src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"}
                alt="travel"
              /> */}
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">kickstart your</span>
                <h2 className="title">Career!</h2>
                <p>
                  If you want to explore and develop yourself professionally and
                  enhance your skill set, join us. With us you’ll be able to
                  explore varied horizons and be able to push boundaries. Humans
                  don’t grasp things easily but practical execution makes a
                  difference.
                </p>
                <p>
                  We have clients world over, which will definitely give you a
                  wide exposure leading you to gain considerable experience
                  working in varied team sizes in different time zones with
                  disparte.
                </p>
                <p>
                  We look for candidates who have a wide outlook and a bold
                  personality, such people don’t fear anything and keep pushing
                  the limits. You’ll be challenged, pushed and even reprimanded
                  but eventually this will favor you and help you enhance your
                  aptitude and temperament.
                </p>
                <Link
                  to="/about-us"
                  className="axil-btn btn-fill-primary btn-large"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSeven;
