import React, { useState, useEffect } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import seoData from "../seoData/VIsitorManagementSystem.json";
import visitor from "../assets/image/solution/visitor.png";
import AboutNIne from "../component/about/AboutNIne";
import AboutTen from "../component/about/AboutTen";
import AboutEleven from "../component/about/AboutEleven";
import { Link } from "react-scroll";
import { db } from "../../src/firebase"; // Import your existing Firebase setup
import { doc, setDoc } from "firebase/firestore"; // Firestore methods
import { FaTimes } from "react-icons/fa";
import VisitorFaqDetails from "./VisitorFaqDetails";
import VisitorPlan from "./VisitorPlan";
import VisitorQuickDemo from "./VisitorQuickDemo";

const VisiterManageSystem = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Clear error message for the respective field when the user starts typing
    setFormErrors({ ...formErrors, [name]: "" });

    setFormData({ ...formData, [name]: value });
  };

  // Phone validation function
  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    const errors = {
      name: "",
      phone: "",
    };

    if (!formData.name) {
      errors.name = "Name is required.";
    }

    if (!formData.phone) {
      errors.phone = "Phone number is required.";
    } else if (!validatePhone(formData.phone)) {
      errors.phone = "Phone number must be 10 digits.";
    }

    if (errors.name || errors.phone) {
      setFormErrors(errors);
      return; // If there are errors, stop the form submission
    }

    try {
      const docRef = doc(db, "userDetails", formData.phone);
      await setDoc(docRef, {
        name: formData.name,
        phone: formData.phone,
      });

      // Reset form fields and error messages after successful submission
      setFormData({
        name: "",
        phone: "",
      });
      setFormErrors({
        name: "",
        phone: "",
      });

      console.log("Document successfully written!");
      window.open("https://visitordemo.itcodeinfotech.com/", "_blank");

      // Optionally close the form if it is still open
      setFormVisible(false);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      console.log(window.scrollY, "scroll");
      setScroll(window.scrollY > 200);
    });
  }, []);

  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne
          title="IN OUT(Visitor Management System)"
          page="IN OUT(Visitor Management System)"
        />
        <div className="">
          <Link
            className="button-wiggle position-fixed demo_btn rounded-pill"
            to="#"
            // onClick={() =>
            //   window.open("https://visitordemo.itcodeinfotech.com/", "_blank")
            // }
            onClick={() => setFormVisible(true)}
          >
            Quick Demo
          </Link>
        </div>
        {formVisible && (
          <div className="popup-overlay">
            <div className="popup-container">
              <div className="popup-header d-flex justify-content-between">
                <h3 className="popup-title">Don't Miss the Chance!</h3>
                <button
                  className="btn-close"
                  onClick={() => setFormVisible(false)}
                >
                  <FaTimes />
                </button>
              </div>
              <div className="popup-body">
                <p className="popup-subtitle">
                  Join us and experience the Visitor Management System.
                </p>
                <form onSubmit={handleSubmit} className="popup-form">
                  <div className="mb-3">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                    {formErrors.name && (
                      <div className="error-message text-danger text-start">
                        {formErrors.name}
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                    {formErrors.phone && (
                      <div className="error-message text-danger text-start">
                        {formErrors.phone}
                      </div>
                    )}
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Book Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="mx-auto col-xl-9 col-lg-9">
                <div
                  className="contact-form-box shadow-box p-4 text-center pt-0"
                  style={{ border: "2px dashed #5cb3e3" }}
                >
                  {/* <h3 className="title">Contact our recruitment team</h3>
                  <p>
                    Fill up the form and our Team will get back to you within 24
                    hours.
                  </p> */}
                  <img src={visitor} alt="visitor" className="" />
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <nav
          id="onepagenav"
          className={`service-scroll-nav navbar ${
            scroll ? "onepagefixed" : "d-none"
          }`}
        >
          <div className="container">
            <ul className="nav nav-pills">
              <li className="nav-item">
                {/* <a
                    className={`nav-link`}
                    href="#section1"
                  > */}
                <Link activeClass="kushal-class" smooth spy to="section1">
                  Faq
                </Link>
                {/* </a> */}
              </li>
              <li className="nav-item">
                <Link activeClass="kushal-class" smooth spy to="section2">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                {/* <a className="nav-link" href="#section3"> */}
                <Link activeClass="kushal-class" smooth spy to="section3">
                  What is
                </Link>
                {/* </a> */}
              </li>
              <li className="nav-item">
                {/* <a className="nav-link" href="#section4"> */}
                <Link activeClass="kushal-class" smooth spy to="section4">
                  Quick demo
                </Link>
                {/* </a> */}
              </li>
              <li className="nav-item">
                {/* <a className="nav-link" href="#section5"> */}
                <Link activeClass="kushal-class" smooth spy to="section5">
                  Document download
                </Link>
                {/* </a> */}
              </li>
            </ul>
          </div>
        </nav>
        <div id="section1">
          <VisitorFaqDetails />
        </div>
        <div id="section2">
          <VisitorPlan />
        </div>
        <div id="section3">
          <AboutNIne />
          <AboutTen />
          <AboutEleven />
        </div>
        <div id="section4">
          <VisitorQuickDemo />
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default VisiterManageSystem;
