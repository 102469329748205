import React, { useRef, useState } from "react";
import { FaEnvelopeOpen, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import Alert from "react-bootstrap/Alert";
// import ServiceData from "../../data/service/ServiceMain.json";

// const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  const form = useRef();

  const [formData, setFormData] = useState({
    email: "",
  });
  const [result, showresult] = useState(false);
  const [loading, setLoading] = useState();

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("Form ref ==>", form.current);
    console.log("Form data ==>", formData);

    const ref = collection(db, "subscribe");
    await addDoc(ref, {
      ...formData,
    });
    setFormData({
      email: "",
    });
    showresult(true);
    setTimeout(() => {
      showresult(false);
    }, 10000);
    setLoading(false);

    //     emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //       form.current.reset();
    //       showresult(true);
  };

  const Result = () => {
    return (
      <Alert variant="success" className="success-msg">
        Your email has been successfully subscribed!.
      </Alert>
    );
  };
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        {/* <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled ps-0">
                            <li><Link to="https://facebook.com/"><FaFacebookF /></Link></li>
                            <li><Link to="https://twitter.com/"><FaTwitter /></Link></li>
                            <li><Link to="https://www.pinterest.com/"><FaPinterestP /></Link></li>
                            <li><Link to="https://www.linkedin.com/"><FaLinkedin /></Link></li>
                            <li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>
                            <li><Link to="https://vimeo.com/"><FaVimeoV /></Link></li>
                            <li><Link to="https://dribbble.com/"><FaDribbble /></Link></li>
                            <li><Link to="https://www.behance.net/"><FaBehance /></Link></li>
                        </ul>
                    </div>
                </div> */}
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Get in touch!</h2>
                  <p>
                    Drop your email address here, so that we can reach out to
                    you and address your concerns.
                  </p>
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="input-group">
                      <span className="mail-icon">
                        <FaEnvelopeOpen />{" "}
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />
                      <button className="subscribe-btn" type="submit">
                        {loading ? "Please wait" : "Subscribe"}
                      </button>
                    </div>
                    <div className="form-group">
                      {result ? <Result /> : null}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Our Services</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {/* {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>
                                                    </li>
                                                ))} */}
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/web-development"}
                          >
                            Web Development
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              process.env.PUBLIC_URL + "/mobile-app-development"
                            }
                          >
                            Mobile App Development
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/ui-ux-design"}>
                            UI/UX Design
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/service"}>
                            QA & Testing
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/consultancy"}>
                            IT Consultancy
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={process.env.PUBLIC_URL + "/Staff-Augmentation"}
                          >
                            Dedicated Team
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-6 px-sm-0">
                      <div className="footer-widget mb-0">
                        <h6 className="widget-title">Resourses</h6>
                        <div className="footer-menu-link">
                          <ul className="list-unstyled">
                            {/* <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link></li> */}
                            <li>
                              {" "}
                              <Link to={process.env.PUBLIC_URL + "/career"}>
                                Career
                              </Link>
                            </li>
                            <li>
                              {" "}
                              <Link to={process.env.PUBLIC_URL + "/about-us"}>
                                About Us
                              </Link>
                            </li>
                            <li>
                              <Link to={process.env.PUBLIC_URL + "/portfolio"}>
                                Portfolio
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 px-sm-0">
                      <div className="footer-widget mb-0">
                        <h6 className="widget-title">Support</h6>
                        <div className="footer-menu-link">
                          <ul className="list-unstyled">
                            <li>
                              <Link to={process.env.PUBLIC_URL + "/contact"}>
                                Contact
                              </Link>
                            </li>
                            <li>
                              {" "}
                              <Link
                                to={process.env.PUBLIC_URL + "/privacy-policy"}
                              >
                                Privacy Policy
                              </Link>
                            </li>
                            <li>
                              <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                                Terms of Use
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 px-sm-0 mt-3">
                      <div className="footer-widget mb-0">
                        <h6 className="widget-title mb-3">Social Link</h6>
                        <div className="footer-social-link ">
                          <ul className="list-unstyled ps-0 justify-content-start">
                            <li>
                              <a href="https://www.linkedin.com/company/itcodehelp/mycompany/">
                                <FaLinkedin />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/itcode_infotech/">
                                <FaInstagram />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="/">ItCodeInfotech</a>.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/faqs"}>Faqs</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
