import React from "react";
import { Link } from "react-router-dom";
import Tilty from "react-tilty";
import SectionTitle from "../../elements/section-title/SectionTitle";
import checkin from "../../assets/image/solution/checkin.png";
import scanqr from "../../assets/image/solution/scanQR.png";
import visitorphoto from "../../assets/image/solution/visitorphoto.png";
import visitorid from "../../assets/image/solution/visitorid.png";

const AboutTen = () => {
  const features = [
    {
      title: "Check in Page",
      image: checkin,
      isImageLeft: false,
      description: [
        "The check-in page is designed for quick data entry, allowing visitors to fill in essential details such as name, contact information, and purpose of visit. This layout speeds up the check-in process significantly.",
        "It also includes fields for capturing visitor photos and ID verification directly on the page. These features provide a complete security overview and ensure accurate identity tracking.",
        "A generated QR code appears on the page, which visitors can use for re-entry or identification. This QR code enhances both security and convenience for returning visitors.",
      ],
    },
    {
      title: "WhatsApp Messaging & QR Code",
      image: scanqr,
      isImageLeft: true,
      description: [
        "The WhatsApp messaging feature sends visitors instant check-in confirmations, providing clear instructions on entry protocols. This direct communication improves visitor engagement and ensures they are informed throughout their visit.",
        "Visitors receive QR codes through WhatsApp, which they can use for quick re-entry, minimizing check-in time. This digital pass simplifies the process and reduces the need for physical paperwork.",
        "The QR code is unique to each visitor, linking back to their registered details for secure identification. This feature enhances security by ensuring only verified individuals access the premises.",
      ],
    },
    {
      title: "Take Visitor Photo",
      image: visitorphoto,
      isImageLeft: false,
      description: [
        "The visitor photo feature captures each visitor's image during check-in, providing a visual record for security. This quick photo capture ensures accurate identification and enhances safety measures.",
        "Photos are stored with visitor information, allowing admins to easily review past check-ins if needed. This organized storage aids in both record-keeping and efficient visitor management.",
        "The feature also makes re-entry smoother, as security can verify visitors visually. This adds an extra layer of verification, complementing QR codes and ID checks.",
      ],
    },
    {
      title: "Print Visitor ID",
      image: visitorid,
      isImageLeft: true,
      description: [
        "The visitor ID printing feature generates a physical pass with essential visitor details, such as name, photo, and visit purpose. This ID serves as a quick reference for both visitors and security staff.",
        "Each printed ID includes a unique QR code for efficient re-entry, enhancing both security and convenience. This minimizes the need for repeat check-in steps on subsequent visits.",
        "The ID pass also displays the check-in and expiry times, helping to manage visitor access duration. This feature supports controlled, timed access to the premises.",
      ],
    },
  ];

  const renderFeature = (feature, index) => {
    const ContentSection = () => (
      <div className="col-xl-5 col-lg-6">
        <div className="case-study-featured mb-0">
          <div className="section-heading heading-left mb-0">
            <span className="subtitle">QuickPass Front-end</span>
            <h2 className="title">{feature.title}</h2>
            {feature.description.map((paragraph, i) => (
              <p key={i}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>
    );

    const ImageSection = () => (
      <div className="col-xl-7 col-lg-6 px-lg-5 px-2">
        <div className="case-study-featured-thumb text-start">
          <Tilty perspective={2000} reset={false}>
            <img
              src={feature.image}
              alt="travel"
              style={{
                borderRadius: "15px",
                boxShadow: "0px 0px 6px -2px #00000063",
              }}
            />
          </Tilty>
        </div>
      </div>
    );

    return (
      <div
        key={index}
        className="section section-padding case-study-featured-area"
      >
        <div className="container">
          <div className="row">
            {feature.isImageLeft ? (
              <>
                <ImageSection />
                <ContentSection />
              </>
            ) : (
              <>
                <ContentSection />
                <ImageSection />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="">
        <div className="container">
          <div className="section-heading text-start mb-0">
            <h2 className="title">QuickPass Front-end</h2>
            <p className="w-100">
              The front end of our website is designed around a seamless
              QuickPass experience, enabling fast visitor check-in with
              essential info entry, photo capture, and ID verification.
              Integrated WhatsApp messaging provides instant check-in
              confirmations, while unique QR codes streamline re-entry and
              enhance security. This setup ensures an efficient, user-friendly
              visitor management system.
            </p>
          </div>
        </div>
      </div>
      {features.map((feature, index) => renderFeature(feature, index))}
    </>
  );
};

export default AboutTen;
