import React from "react";
import { Link } from "react-router-dom";
// import { slugify } from "../../utils";

const AllData = [
  // {
  //   image: "/images/icon/icon-2.png",
  //   title: "Strategy",
  //   // list: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
  //   list: [{ item: "One" }, { item: "One2" }, { item: "One32" }],
  //   description:
  //     "We carry more than just good coding skills. Our experience makes us stand out from other web development.",
  // },
  {
    image: "/images/icon/icon-4.png",
    title: "Marketing",
    description:
      "Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.",
  },
  {
    image: "/images/icon/icon-5.png",
    title: "IT Solutions",
    description:
      "Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate apps.",
  },
  {
    image: "/images/icon/icon-6.png",
    title: "Staff Augmentation",
    description:
      "Over the past decade, our customers succeed by leveraging Intellectsoft’s process of building, motivating.",
  },
];

const ServicePropOne = ({ colSize, serviceStyle, itemShow, marginTop }) => {
  const topMargin = (index) => {
    if (marginTop === "yes") {
      if (index === 0) {
        return "mt--200 mt_md--0";
      } else if (index === 1) {
        return "mt--100 mt_md--0";
      } else {
        return "mt--0";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      {AllData.slice(0, itemShow).map((data, index) => (
        <div className={`${colSize} ${topMargin(index)}`} key={index}>
          <div className={`services-grid ${serviceStyle}`}>
            <div className="thumbnail">
              <img src={process.env.PUBLIC_URL + data.image} alt="icon" />
            </div>
            <div className="content">
              <h5 className="title">
                {/* <Link
                  to={
                    process.env.PUBLIC_URL +
                    `/service-details/${slugify(data.title)}`
                  }
                >
                  {data.title}
                </Link> */}
                <p>{data.title}</p>
              </h5>
              {/* <ul>
                <li>{AllData.list[0]}</li>
              </ul> */}
              <p>{data.description}</p>
              <Link
                to={process.env.PUBLIC_URL + `/service`}
                className="more-btn"
              >
                Find out more
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServicePropOne;
