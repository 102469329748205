import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import AboutFour from "../component/about/AboutFour";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

import greview from "../assets/image/Google_review.svg"
import gdreview from "../assets/image/Glassdoor_review.svg"
import creview from "../assets/image/Clutch_review.svg"
import box1 from "../assets/image/box1.webp"
import box2 from "../assets/image/box2.webp"
import box3 from "../assets/image/box3.webp"
import seoData from "../seoData/AboutUs.json"

const AboutUs = () => {
  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="About us" page="About us" />
        <AboutFour />
        <CounterUpOne />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mt-4">
              <div className="career-box">
                <div className="career-box-img">
                  <img
                    className="img-fluid"
                    src={box1}
                    alt=""
                  />
                </div>
                <h4>What we can do?</h4>
                <p>
                  We put a strong focus on the needs of your business to figure
                  out solutions that best fits your demand and nail it.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-4">
              <div className="career-box">
                <div className="career-box-img">
                  <img
                    className="img-fluid"
                    src={box2}
                    alt=""
                  />
                </div>
                <h4>Become our partners?</h4>
                <p>
                  Our preventive and progressive approach will help you take the
                  lead while addressing possible threats in managing data.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-4">
              <div className="career-box">
                <div className="career-box-img">
                  <img
                    className="img-fluid"
                    src={box3}
                    alt=""
                  />
                </div>
                <h4>Need a hand?</h4>
                <p>
                  Our support team is available 24*7 a day to solve any of your problems & concerns.
                </p>
              </div>
            </div>
          </div>
          <p className="text-center mb-5">
            Challenges are just opportunities in disguise.{" "}
            <a href="/" >Take the challenge!</a>
          </p>

          <div className="rate-row">
            <div
              className="row align-items-center text-center aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="col-12 col-lg-12">
                <div className="review-h4">
                  <h4>Read our reviews on:</h4>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="review">
                  <img
                    src={greview}
                    className=" ls-is-cached lazyloaded"
                    alt="review"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="review review-border">
                  <img
                    src={gdreview}
                    className=" ls-is-cached lazyloaded"
                    alt="review"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="review">
                  <img
                    src={creview}
                    className=" ls-is-cached lazyloaded"
                    alt="review"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parent className="" />
      </main>
    </>
  );
};

export default AboutUs;
