import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import AboutSeven from "../component/about/AboutSeven";
import FormCareer from "../component/contact/FormCareer";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import seoData from "../seoData/Career.json"

const Career = () => {
  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Career" page="Career" />
        <AboutSeven />
        <div className="container">
          <div className="row">
            <div className="mx-auto col-xl-9 col-lg-9">
              <div className="contact-form-box shadow-box mb--100">
                <h3 className="title">Contact our recruitment team</h3>
                <p>
                  Fill up the form and our Team will get back to you within 24
                  hours.
                </p>
                <FormCareer />
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Career;
