import { addDoc, collection } from "firebase/firestore";
import React, { useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { db } from "../../firebase";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormTwo = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });

  const [result, showresult] = useState(false);
  const [loading, setLoading] = useState();

  const sendEmail = async(e) => {
    e.preventDefault();
    setLoading(true)
    console.log("Form ref ==>", form.current);
    console.log("Form data ==>", formData);

    // emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
    //   .then((result) => {
    //       console.log(result.text);
    //   }, (error) => {
    //       console.log(error.text);
    //   });
    //   form.current.reset();
    //   showresult(true);
    const ref = collection(db, "inquiry");
    await addDoc(ref, {
      ...formData,
    });
    setFormData({
      name: "",
      mobile: "",
      email: "",
      message: "",
    });
    showresult(true);
    setLoading(false)
    setTimeout(() => {
        showresult(false);
      }, 15000);
    // emailjs
    //   .sendForm(
    //     "service_yj5dgzp",
    //     "template_hfduayo",
    //     form.current,
    //     "WLENsTkBytC0yvItS"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
    // form.current.reset();
    // showresult(true);
  };

  


  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="contact-name"
          required
          value={formData.name}
          onChange={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="contact-email"
          required
          value={formData.email}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
        />
      </div>
      <div className="form-group">
        <label>Phone</label>
        <input
          type="tel"
          className="form-control"
          name="contact-phone"
          required
          value={formData.mobile}
          onChange={(e) => {
            setFormData({ ...formData, mobile: e.target.value });
          }}
        />
      </div>
      <div className="form-group mb--40">
        <label>How can we help you?</label>
        <textarea
          className="form-control"
          name="contact-message"
          rows="4"
          value={formData.message}
          onChange={(e) => {
            setFormData({ ...formData, message: e.target.value });
          }}
        ></textarea>
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
          disabled={loading}
        >
          {loading ? "Please wait" : "Submit"}
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormTwo;
