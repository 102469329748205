import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import seoData from "../seoData/PrivacyPolicy.json"
const PrivacyPolicy = () => {
  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">
                      This Privacy policy was published on September 10th, 2021.
                    </h5>
                  </div>
                  <p>
                    Thank you for choosing us and being a part of our community
                    ITCode Infotech (“Company”, “we”, “us”, “our”). We are
                    committed to protecting your personal information and your
                    right to privacy.
                  </p>
                  <p>
                    For any questions or concerns about this privacy notice or
                    our practices with respect to your personal information,
                    please contact us at&nbsp;
                    <a href="mailto:info@itcodeinfotech.com">
                      info@itcodeinfotech.com
                    </a>
                  </p>
                  <p>
                    When you visit our website{" "}
                    <a href="/">www.itcodeinfotech.com</a> (the “Website”), and
                    use any of our services (the “Services”, included in the
                    Website), we assume that you are trusting us with your
                    personal information.
                  </p>
                  <p>
                    We respect your Right to Privacy and your information. In
                    this privacy notice, we intend to explain to you in the most
                    understandable manner - what information do we collect, how
                    do we use it, and what rights do you possess with respect to
                    the same.
                  </p>
                  <p>
                    We look forward to you, taking some time to read through
                    these carefully, because of its importance. If you disagree
                    with any of the terms in this privacy notice, please
                    discontinue the use of our services immediately.
                  </p>
                  <p>
                    This privacy notice applies to all information collected
                    through our Services (as described above, including our
                    website), as well as, any related services, sales,
                    marketing, or events.
                  </p>
                  <p>
                    Please read this privacy notice carefully so that you are
                    familiar and understand what we do with the information we
                    collect.
                  </p>
                  <h4>1. What information do we collect?</h4>
                  <p>
                    The personal information you disclose to us, i.e., personal
                    information that you produce voluntarily while expressing
                    your interest in gaining information about us or our
                    products and services, when you participate in activities on
                    the website or else when you contact us.
                  </p>
                  <p>
                    The information that we collect depends on the context of
                    your interactions with us and the website, the choices you
                    make, and the products and features you use. The information
                    that we collect may include the following:
                  </p>
                  <p>
                    Your credentials - names, phone numbers, email addresses,
                    mailing addresses, etc.
                  </p>
                  <p>
                    The information you produce must be true, complete, and
                    accurate, and you must notify us of any changes to such
                    information.
                  </p>
                  <h5>Information that is collected automatically:</h5>
                  <p>
                    When you access our website, some information is
                    automatically gathered, such as your Internet Protocol (IP)
                    address and/or browser and device details. When you visit,
                    use, or navigate the website, we automatically collect some
                    information. This information does not specifically identify
                    you but may reveal information about your device and usage,
                    including your IP address, browser and device
                    characteristics, operating system, language preferences,
                    referring URLs, device name, country, and location, as well
                    as technical data about when and how you use our website.
                    This data is largely required for internal analytics and
                    reporting, as well as to ensure the security and
                    functionality of our website.
                  </p>
                  <p>
                    Like other businesses, we also collect information through
                    cookies and alike.
                  </p>
                  <h4>The information we collect includes:</h4>
                  <h5>Log and Usage Data</h5>
                  <p>
                    Log and usage data are service-related, diagnostic, usage,
                    and performance information our servers automatically
                    collect when you access or use our Website and which we
                    record in log files. Depending on how you interact with us,
                    this log data may include your IP address, device
                    information, browser type, and settings, and information
                    about your activity on the Website (such as the date/time
                    stamps associated with your usage, pages, and files viewed,
                    searches and other actions you take such as which features
                    you use), device event information (such as system activity,
                    error reports (sometimes called ‘crash dumps’) and hardware
                    settings).
                  </p>
                  <h5>Device Data</h5>
                  <p>
                    We collect device data such as information about your
                    computer, phone, tablet, or other devices you use to access
                    the Website. Depending on the device used, this device data
                    may include information such as your IP address (or proxy
                    server), device and application identification numbers,
                    location, browser type, hardware model Internet service
                    provider and/or mobile carrier, operating system, and system
                    configuration information.
                  </p>
                  <h5>Location Data</h5>
                  <p>
                    We collect location data such as information about your
                    device’s location, which can be either precise or imprecise.
                    How much information we collect depends on the type and
                    settings of the device you use to access the Website. For
                    example, we may use GPS and other technologies to collect
                    geolocation data that tells us your current location (based
                    on your IP address). You can opt-out of allowing us to
                    collect this information either by refusing access to the
                    information or by disabling your Location setting on your
                    device.
                  </p>
                  <p>
                    Note: however, if you choose to opt-out, you may not be able
                    to use certain aspects of the Services.
                  </p>
                  <h4>2. How do we use your information?</h4>
                  <p>
                    In order to complete our relationship with you, comply with
                    legal requirements, or with your consent, we process your
                    information based on our legitimate business interests. We
                    utilise the personal data we receive through our website for
                    a number of business objectives that are detailed below. We
                    use your personal information for these purposes in
                    accordance with your consent, our legal obligations, or our
                    legitimate business interests in order to enter into or
                    carry out a contract with you. Next to each of the
                    aforementioned purposes, we list the specific processing
                    justifications we rely on.
                  </p>
                  <p>
                    We give you administrative information using the data we
                    gather or receive. We may use your personal data to send you
                    information about our products, services, new features,
                    and/or updates to our terms, conditions, and policies.
                  </p>
                  <p>
                    To keep our services safe. In an effort to maintain the
                    security and safety of our website, we may use the
                    information you provide to us (for example, for fraud
                    monitoring and prevention).
                  </p>
                  <p>
                    To connect with our contract, to adhere to legal and
                    regulatory requirements, or to enforce our terms,
                    conditions, and policies.
                  </p>
                  <p>
                    To fulfill judicial demands and shield people from danger.
                    To decide how to react to a subpoena or other legal request,
                    we might need to look over the data we currently have.
                  </p>
                  <p>
                    To send you marketing and advertising materials. If this is
                    in accordance with your marketing preferences, we and/or our
                    third-party marketing partners may use the personal
                    information you give to us for our marketing efforts. We
                    will get personal information from you, for instance,
                    whether you show an interest in learning more about us or
                    our website, sign up for marketing, or otherwise get in
                    touch with us.
                  </p>
                  <p>
                    You can opt out of our marketing emails any time (see the
                    “WHAT ARE YOUR PRIVACY RIGHTS?” below).
                  </p>
                  <p>
                    To deliver targeted advertising to you, we may use your
                    information to develop and display personalized content and
                    advertising (and work with third parties who do so) tailored
                    to your interests and/or location and to measure its
                    effectiveness.
                  </p>
                  <h4>3. Do we share your information with anyone?</h4>
                  <p>
                    We share your information only with your consent. To comply
                    with laws, provide you services, protect your rights, or to
                    fulfill business obligations.
                  </p>
                  <p>
                    We may process or share your data that we hold based on the
                    following legal basis:
                  </p>
                  <p>
                    Consent: We may process your data if you give consent to us
                    to use your information for a specific purpose.
                  </p>
                  <p>
                    Legitimate Interests: We may process your data when it is
                    reasonably necessary to achieve our legitimate business
                    interests.
                  </p>
                  <p>
                    Performance of a Contract: When we are bound by a contract
                    between us, we may process your personal information to
                    fulfill the terms of your contract.
                  </p>
                  <p>
                    Legal Obligations: We may disclose your information whenever
                    we are legally bound to do so, in order to comply with
                    applicable law, governmental requests, a judicial
                    proceeding, court order, or legal processes, such as in
                    response to a court order or a subpoena (including in
                    response to public authorities to meet national security or
                    law enforcement requirements).
                  </p>
                  <p>
                    Vital Interests: When we feel it is necessary to look into
                    potential policy violations, prevent or take action
                    regarding suspected fraud, circumstances involving potential
                    threats to the safety of any person, or as evidence in legal
                    proceedings in which we are involved, we may release your
                    information.
                  </p>
                  <p>
                    To be precise, we may need to process your data or share
                    your personal information in the following situations:
                  </p>
                  <p>
                    Business Transfers. We may share or transfer your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.
                  </p>
                  <h4>4.Do we use cookies and other tracking technologies?</h4>
                  <p>
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in our Cookie
                    Notice.
                  </p>
                  <h4>5. How long do we retain your information?</h4>
                  <p>
                    We will only keep your personal information as long as it is
                    necessary for the purposes set out in this privacy notice
                    unless a longer retention period is required or permitted by
                    law (such as tax, accounting, or other legal requirements).
                    No purpose in this notice will require us to keep your
                    personal information for more than 2 years.
                  </p>
                  <h4>6. How do we keep your information safe?</h4>
                  <p>
                    We protect your personal information through a system of
                    organizational and technical security measures.
                  </p>
                  <p>
                    We have relevant technical and organizational security
                    measures in place designed to protect the security of any
                    personal information we process. However, despite our
                    safeguards and efforts to secure your information, no
                    electronic transmission over the Internet or information
                    storage technology can be guaranteed to be 100% secure, so
                    we cannot promise or guarantee that hackers, cybercriminals,
                    or other unauthorized third parties will not be able to
                    defeat our security, and improperly collect, access, steal,
                    or modify your information.
                  </p>
                  <p>
                    Although we'll do everything we can to keep your personal
                    information safe, you bear all risk when sending personal
                    information to or from our website. You should only use a
                    secure environment to access the website.
                  </p>
                  <h4>7. Do we collect information from minors?</h4>
                  <p>
                    We do not intentionally collect information from minors
                    under the age of 18 or market to them. By using the website,
                    you certify that you are at least 18 years old or that you
                    are the parent or legal guardian of a child who will be
                    using it with your permission. If we become aware that a
                    user under the age of 18 has provided us with personal
                    information, we will immediately deactivate the account and
                    take reasonable steps to ensure that the information is
                    removed from our files. Please get in touch with us
                    at_________ if you learn of any information we may have
                    obtained from people under the age of 18.
                  </p>
                  <h4>8. Controls for do-not-track features</h4>
                  <p>
                    You can turn on the Do-Not-Track ("DNT") feature or setting
                    in the majority of web browsers, some mobile operating
                    systems, and mobile applications to indicate that you prefer
                    not to have information about your online browsing
                    activities tracked and collected. There is currently no
                    established uniform technology standard for identifying and
                    utilizing DNT signals.
                  </p>
                  <p>
                    As such, we do not currently respond to DNT browser signals
                    or any other mechanism that automatically communicates your
                    choice not to be tracked online. If a standard for online
                    tracking is adopted that we must follow in the future, we
                    will inform you about that practice in a revised version of
                    this privacy notice.
                  </p>
                  <h4>9. Do we update this notice?</h4>
                  <p>
                    Yes, this notice is updated as and when required to comply
                    with applicable laws. This privacy notice may occasionally
                    be updated. An updated "Revised" date will be used to
                    identify the updated version, and it will take effect as
                    soon as it is made available. If we make significant changes
                    to this privacy statement, we'll let you know by either
                    prominently posting a notice of the changes or by sending
                    you a personal message. We encourage you to review this
                    privacy notice frequently to be informed of how we are
                    protecting your information.
                  </p>
                  <h4>
                    10. How can you contact us with regard to this notice?
                  </h4>
                  <p>
                    If you have any questions or suggestions about this notice,
                    kindly email us at&nbsp;
                    <a href="mailto:info@itcodeinfotech.com">
                      info@itcodeinfotech.com
                    </a>
                  </p>
                  <h4>
                    11. How can you review, update, or delete the data we
                    collect from you?
                  </h4>
                  <p>
                    You may be able to request access to the personal data we
                    collect about you, make changes to that information, or in
                    some cases, have it deleted, depending on the laws that
                    apply in your country. Please use this form to submit a
                    request to review, update, or delete your personal
                    information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
