import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

import argumentDeveloper from "../assets/image/services/argumentDeveloper.svg";
import argumentTeam from "../assets/image/services/argumentTeam.svg";
import rightArrowSmall from "../assets/image/services/rightArrowSmall.svg";
// import argumentTeam from "../assets/image/services/argumentTeam.svg"
// import liIcon from "../li-icon.svg"
import seoData from "../seoData/StaffAugmentation.json"

const StaffAugmentation = () => {
  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Hire Are Developer" page="Hire Are Developer" />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <p className="mb-3">
                Professionals cite a lack of skills as a barrier to advancing
                digital transformation!
              </p>
              <p className="mb-3">
                Power your organization by hiring the additional resources. Our
                whole team possesses experience of over 2 years ranging between
                2-5 years. Not just this, we offer a 7-day risk-elimination
                period beginning from the day you hire a developer, within which
                you can replace or relieve a developer in case you change your
                mind.
              </p>
              <p>
                Reach us, we’ll discuss your requirements and find a best match.
              </p>
              {/* <LiIcon /> */}
              {/* <ul style="list-style-image: url('../../public/images/liIcon.svg')" > */}
              <ul
                style={{
                  listStyleImage: `url(https://tradeunlistedimage.s3.ap-south-1.amazonaws.com/li-icon.svg)`,
                }}
              >
                <li>
                  Avail our staff augmentation services to address your business
                  requirements and achieve the desired results.
                </li>
                <li>
                  Recruit our developers to bridge the gap in your team and
                  conclude your projects successfully and efficiently.
                </li>
                <li>
                  Outsourcing the tasks in such a way would help you cut down
                  the additional cost the company would have to borne otherwise,
                  which you can utilize elsewhere.
                </li>
              </ul>
            </div>
            <section id="augmentation-card" className="section-padding pb-0">
              <div className="row mb-5">
                <h4 className="p-3">
                  These are various options for you to match your requirements:
                </h4>
                <div className="col-11 mx-auto col-lg-6">
                  <div className="augmentation-card">
                    <div className="d-flex gap-3 align-items-center">
                      <img src={argumentTeam} alt="" />
                      <h6>Employ a Commited Team</h6>
                    </div>
                    <p className="mt-2">
                      You’ll get a well knit and professional team of
                      developers, who’ll work dedicatedly for you. Overcome the
                      skill gaps, receive technical guidance and their inputs
                      throughout the development process, reduce functional
                      expenditures, and simply fulfill your strategic goals.
                    </p>
                    <div className="explore">
                      Explore
                      <img src={rightArrowSmall} className="ms-2" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-11 mx-auto col-lg-6 mt-3 mt-lg-0">
                  <div className="augmentation-card">
                    <div className="d-flex gap-3 align-items-center">
                      <img src={argumentDeveloper} alt="" />
                      <h6>Employ a dedicated developer</h6>
                    </div>
                    <p className="mt-2">
                      To satisfy your company's demands, we offer a validated
                      set of talented developers, and testers. The knowledge and
                      efficiency they possess will help you deliver top-notch
                      and user-friendly solutions.
                    </p>
                    <div className="explore">
                      Explore
                      <img src={rightArrowSmall} className="ms-2" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4">
                <div className="section-header">
                  <h4 className="mb-3">What do we offer?</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-11 col-lg-9 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title">1. Swift Access</div>
                    <p className="mb-0 mt-3">
                      We’ve 65+ in-house developers with varied skill-set. You
                      can avail the resource as per your need. And the best part
                      is that they would work remotely, thus, no need to house
                      them. The whole process is smooth and sorted. They are
                      just a call or message away.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num2">
                      2. Flexible Pricing
                    </div>
                    <p className="mb-0 mt-3">
                      We offer flexible hiring modules, viz. - Hourly,
                      Task-based or Fixed monthly payments - whatever fits your
                      need.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      3. Long - Lasting Co-operation
                    </div>
                    <p className="mb-0 mt-3">
                      When the developers work with your teams, they familiarize
                      with the project and can help you maintain the project in
                      the long- run without having the need to first explain
                      things, rather just hop-on and get to work.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num4">
                      4. Risk Free Hiring
                    </div>
                    <p className="mb-0 mt-3">
                      In case the developers don’t fit your requirements or
                      conditions, you can avail our 7-day relaxation period
                      within which we can replace the developer for you or
                      relieve one in case you change your mind.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default StaffAugmentation;
