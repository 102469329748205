import React from "react";
import { Link } from "react-router-dom";
import Tilty from "react-tilty";
import SectionTitle from "../../elements/section-title/SectionTitle";
import desboard from "../../assets/image/solution/ademindashboard.png";
import profile from "../../assets/image/solution/profile.png";
import department from "../../assets/image/solution/department.png";
import designation from "../../assets/image/solution/designation.png";
import employee from "../../assets/image/solution/Employeemanage.png";
import visitor from "../../assets/image/solution/visitormanage.png";
import preregister from "../../assets/image/solution/preregister.png";
import attendence from "../../assets/image/solution/attendence.png";
import reports from "../../assets/image/solution/reports.png";
import addministrator from "../../assets/image/solution/addministrator.png";
import languages from "../../assets/image/solution/languages.png";
import settings from "../../assets/image/solution/settings.png";

const AboutEleven = () => {
  const features = [
    {
      title: "Dashboard",
      image: desboard,
      isImageLeft: false,
      description: [
        "The dashboard provides admins with an overview of all visitor and employee activities, including check-in status and visitor flow. This centralized view allows for quick insights and efficient management across the premises.",
        "For employees, the dashboard displays their daily visitor appointments and check-in times, helping them stay organized and prepared. They can also view their work location status, whether in-office or remote.",
        "Receptionists use the dashboard to monitor visitor arrivals and confirm check-ins. This streamlined view helps them manage visitor traffic and coordinate with employees for smooth entry management.",
      ],
    },
    {
      title: "Profile",
      image: profile,
      isImageLeft: true,
      description: [
        "The profile section allows each user—admin, employee, and receptionist—to manage personal information, such as contact details and   profile photo. This ensures that all information remains accurate and up-to-date.",
        "Admins can update roles and access permissions within profiles, tailoring access based on responsibilities. This feature enhances security and aligns access with individual duties.",
        "Employees and receptionists can also customize their profile settings, such as language preference and notification options. This personalization improves user experience and adapts the interface to each user's needs.",
      ],
    },
    {
      title: "Departments",
      image: department,
      isImageLeft: false,
      description: [
        "The department feature organizes employees by their respective departments, making it easy to assign visitors and manage interactions. This structured setup enhances internal organization and visitor routing.",
        "Admins can create, update, or remove departments, ensuring that the system reflects current organizational structure.This flexibility helps keep the system aligned with any changes in the company.",
        "Employees and receptionists can view department information to identify colleagues and assist visitors effectively. This clarity promotes smoother communication and visitor handling across departments.",
      ],
    },
    {
      title: "Designations",
      image: designation,
      isImageLeft: true,
      description: [
        "The designation feature categorizes employees by their roles, such as manager, executive, or assistant, to clarify responsibilities within the system. This setup ensures each employee’s role is accurately represented.",
        "Admins can assign or update designations, enabling specific access levels based on an employee’s role. This role-based access improves security and operational efficiency.",
        "Employees can view designations within the platform, helping them identify colleagues’ roles and collaborate more effectively. This feature supports clear communication and teamwork across different levels.",
      ],
    },
    {
      title: "Employees",
      image: employee,
      isImageLeft: false,
      description: [
        "The employee feature maintains detailed records of each staff member, including contact information and assigned department. This centralized database helps admins manage the workforce efficiently.",
        "Admins can assign roles, departments, and designations, ensuring each employee has appropriate access and responsibilities. This structured approach enhances security and organization within the platform.",
        "Employees can update their own profiles, view assigned visitors, and check their work schedules. This empowers employees to manage their information while supporting smooth daily operations.",
      ],
    },
    {
      title: "Vistors",
      image: visitor,
      isImageLeft: true,
      description: [
        "The visitor feature records essential details like name,contact information, and purpose of visit, ensuring accurate visitor tracking. This data provides a complete record of each visitor's entry.",
        "Admins and receptionists can manage visitor check-in and check-out times, improving oversight and security. Real-time tracking allows quick access to visitor status and movement.",
        "Visitors are issued unique IDs with QR codes, simplifying re-entry and verification. This feature enhances both security and convenience for all guests and staff.",
      ],
    },
    {
      title: "Pre-registers",
      image: preregister,
      isImageLeft: false,
      description: [
        "The pre-register feature allows employees to schedule visitor appointments in advance, capturing details like name, contact, and visit purpose. This prepares the system for smooth visitor check-ins.",
        "Admins and receptionists can view upcoming pre-registered visitors, helping them anticipate visitor traffic. This proactive approach reduces wait times and enhances visitor flow management.",
        "Upon arrival, pre-registered visitors can quickly check in with minimal data entry, as their information is already in the system. This feature improves efficiency and offers a seamless experience for guests.",
      ],
    },
    {
      title: "Attendance",
      image: attendence,
      isImageLeft: true,
      description: [
        "The attendance feature records daily check-ins and check-outs for all employees, providing accurate tracking of work hours. This system supports reliable attendance records for payroll and performance management.",
        "Admins can access detailed attendance reports, including late entries and early exits, to monitor employee punctuality. This helps maintain accountability and encourages timely attendance.",
        "Employees can view their own attendance history, allowing them to track work hours and address discrepancies. This transparency fosters trust and gives employees control over their time records.",
      ],
    },
    {
      title: "Reports",
      image: reports,
      isImageLeft: false,
      description: [
        "The reports feature generates comprehensive data summaries on visitor activity, employee attendance, and department performance. This centralized reporting enables admins to monitor trends and make informed decisions.",
        "Admins can customize report filters, such as by date or department, to focus on specific metrics. This flexibility ensures relevant insights tailored to the organization’s needs.",
        " Generated reports are easy to download and share, supporting seamless collaboration across teams. This accessibility promotes data-driven improvements in operational efficiency and security.",
      ],
    },
    {
      title: "Administrator",
      image: addministrator,
      isImageLeft: true,
      description: [
        "The administrator role has full control over the system, managing features like visitor records, employee profiles, and department settings. This ensures the platform aligns with organizational requirements.",
        " Administrators can set permissions and assign roles,tailoring access levels for employees, receptionists, and other users. This role-based access enhances both security and functionality.",
        "They also have access to detailed reports and analytics,helping them monitor system performance and address issues proactively. This oversight supports smooth operations and efficient resource management.",
      ],
    },
    {
      title: "Language",
      image: languages,
      isImageLeft: false,
      description: [
        "The language feature enables users to select their preferred language for the platform, ensuring ease of use for a diverse audience. This customization improves accessibility and enhances user experience.",
        "Admins can set a default language for the system, while individual users can adjust language settings to suit their needs. This flexibility accommodates multilingual teams and visitors.",
        "With RTL support, the platform caters to languages that read from right to left, such as Arabic. This feature ensures the interface remains intuitive and accessible for all users.",
      ],
    },
    {
      title: "Settings",
      image: settings,
      isImageLeft: true,
      description: [
        "The settings feature provides users with control over their account preferences, including language, notifications, and security. This customization allows users to tailor the platform to their specific needs.",
        "Admins have access to advanced settings, such as permissions, role management, and system configurations.These controls enable the administrator to keep the system secure and organized.",
        " Users can enable or disable notifications, deciding how they receive alerts for check-ins, messages, and other updates.This flexibility helps each user stay informed without overwhelming them.",
        "Security settings, such as password updates and two-factor authentication, enhance account protection for all users.These features support a secure, reliable environment for managing sensitive information.",
        " Additionally, settings allow customization for interface preferences, such as theme options or layout adjustments.This personalization enhances usability, making the platform adaptable to each user's preferences.",
      ],
    },
  ];

  const renderFeature = (feature, index) => {
    const ContentSection = () => (
      <div className="col-xl-5 col-lg-6">
        <div className="case-study-featured mb-0">
          <div className="section-heading heading-left mb-0">
            <span className="subtitle">Key Feature</span>
            <h2 className="title">{feature.title}</h2>
            {feature.description.map((paragraph, i) => (
              <p key={i}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>
    );

    const ImageSection = () => (
      <div className="col-xl-7 col-lg-6 px-lg-5 px-2">
        <div className="case-study-featured-thumb text-start">
          <Tilty perspective={2000} reset={false}>
            <img
              src={feature.image}
              alt="travel"
              style={{
                borderRadius: "15px",
                boxShadow: "0px 0px 6px -2px #00000063",
              }}
            />
          </Tilty>
        </div>
      </div>
    );

    return (
      <div
        key={index}
        className="section section-padding case-study-featured-area"
      >
        <div className="container">
          <div className="row">
            {feature.isImageLeft ? (
              <>
                <ImageSection />
                <ContentSection />
              </>
            ) : (
              <>
                <ContentSection />
                <ImageSection />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="">
        <div className="container">
          <div className="section-heading text-start mb-0">
            <h2 className="title">Key Feature</h2>
            <p className="w-100">
              The website includes a comprehensive dashboard, offering an
              at-a-glance view of visitor stats, employee activity, and system
              performance. The profile section allows users to manage personal
              information, preferences, and access settings easily. The
              department feature organizes employees by department, streamlining
              visitor assignments and internal coordination. The visitor section
              efficiently handles visitor data, from registration to ID
              generation, ensuring a secure and seamless check-in process.
            </p>
          </div>
        </div>
      </div>
      {features.map((feature, index) => renderFeature(feature, index))}
    </>
  );
};

export default AboutEleven;
