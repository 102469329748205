import React from "react";

const VisitorFaqDetails = () => {
  return (
    <>
      <div className="">
        <div className="container">
          <div className="section-padding text-start pb-5">
            <h2 className="title">Faq</h2>
            <div className="content mt-5">
              <h5 className="title">What is the Visitor Management System?</h5>
              <p className="w-100 mb-3">
                A Visitor Management System is a digital tool that simplifies
                and secures the process of managing visitors in our premises. It
                helps track visitor information, ensures compliance with safety
                protocols, and enhances the overall visitor experience by
                reducing manual paperwork and providing real-time updates to
                hosts.
              </p>
              <p className="mb-3">
                Anyone visiting our company, including clients, vendors,
                contractors, interviewees, and personal guests, can use the
                system. It’s designed to be user-friendly and accessible to all.
              </p>
            </div>
            <div className="mt-5 content">
              <h5 className="title">What features does the system include?</h5>
              <p p className="w-100 mb-3">
                Key features include:
              </p>
              <ul style={{ lineHeight: "1.5rem" }}>
                <li>Digital visitor registration</li>
                <li>QR code-based check-ins</li>
                <li>Visitor badge printing</li>
                <li>Pre-registration for scheduled appointments</li>
                <li>Real-time visitor tracking and notifications to hosts</li>
                <li>
                  Take visitor photos for enhanced security and seamless
                  identification.
                </li>
                <li>
                  Offer multiple languages to help international visitors.
                </li>
              </ul>
            </div>
            <div className="content mt-5">
              <h5 className="title"> What happens after I check in?</h5>
              <p className="w-100 mb-3">
                After you successfully check in, the system automatically
                notifies the person or department you’re visiting. This
                notification is typically sent via email or SMS. It includes
                your name, the time you checked in, and the reason for your
                visit, ensuring that the host knows you’ve arrived promptly.
              </p>
              <p className="mb-3">
                You’ll also receive a visitor badge to wear during your visit.
                This badge must be displayed visibly during your visit to
                identify you as an authorized guest.
              </p>
            </div>
            <div className="content mt-5">
              <h5 className="title">Is my information safe?</h5>
              <p className="w-100 mb-4">
                Yes, your privacy is very important to us! Your details are
                securely stored and only used for managing your visit. We only
                collect the information necessary for managing your visit. This
                typically includes:
              </p>
              <ul className="mb-4" style={{ lineHeight: "1.5rem" }}>
                <li>
                  Your name and contact details (to identify and communicate
                  with you)
                </li>
                <li>The purpose of your visit</li>
                <li>The host’s name (to notify them of your arrival)</li>
                <li>ID details or photo (if required for security purposes)</li>
              </ul>
              <p className="w-100 mb-3">
                We also make sure your data is deleted after a set period.
              </p>
            </div>
            <div className="content mt-5">
              <h5 className="title">Who has access to my information?</h5>
              <p className="w-100 mb-4">
                Access to your information is strictly limited to authorized
                personnel, such as:
              </p>
              <ul className="mb-4" style={{ lineHeight: "1.5rem" }}>
                <li>The host you are visiting.</li>
                <li>
                  Security staff or receptionists responsible for visitor
                  management
                </li>
                <li>System administrators who maintain the platform</li>
              </ul>
              <p className="w-100 mb-3">
                No unauthorized individuals or external parties can access your
                data.
              </p>
            </div>
            <div className="content mt-5">
              <h5 className="title"> How Do Visitors Check Out?</h5>
              <p className="w-100 mb-3">
                Visitors can check out from the system using two convenient
                methods:
              </p>
              <ol>
                <li>
                  From the Visitor List:
                  <ul>
                    <li>
                      Authorized personnel can open the list of currently
                      checked-in visitors in the system.
                    </li>
                    <li>Click the “Check Out” option next to their details.</li>
                  </ul>
                </li>
                <li className="mt-3">
                  Using the Checkout Option on the Landing Page:
                  <ul>
                    <li>
                      By Visitor ID: Enter the unique Visitor ID provided during
                      check-in.
                    </li>
                    <li>
                      By Scanning the QR Code: Use the QR code received during
                      check-in to complete the process quickly and
                      contactlessly.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div className="content mt-5">
              <h5 className="title">
                Can the system remember frequent visitors?
              </h5>
              <p className="w-100 mb-3">
                Yes, our system is designed to make check-ins faster and more
                convenient for frequent visitors by offering multiple ways to
                retrieve and prefill their information:
              </p>
              <ol>
                <li>
                  Past Visitor List on Landing Page:
                  <ul>
                    <li>
                      The system displays a list of past visitors directly on
                      the landing page.
                    </li>
                    <li>
                      You can select a name from the list, and their details
                      will automatically fill in the check-in form.
                    </li>
                  </ul>
                </li>
                <li className="mt-3">
                  Mobile Number-Based Autofill:
                  <ul>
                    <li>
                      During check-in, the system prompts for the visitor’s
                      mobile number.
                    </li>
                    <li>
                      If the mobile number is already in the system, their
                      information is fetched and autofilled automatically.
                    </li>
                  </ul>
                </li>
                <li className="mt-3">
                  QR Code Scan:
                  <ul>
                    <li>
                      On the landing page, visitors can scan their unique QR
                      code received during a previous visit.
                    </li>
                    <li>
                      The system retrieves the visitor's information and
                      autofills the check-in form instantly.
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                These features save time and enhance the user experience,
                ensuring smooth and efficient check-ins for frequent visitors.
              </p>
            </div>
            <div className="content mt-5">
              <h5 className="title">
                Is the visitor history updated in real time?
              </h5>
              <p className="w-100 mb-3">
                Yes, the visitor history is updated in real time. As soon as a
                visitor checks in or out, the system logs their details
                instantly. This ensures that the records are always accurate and
                up-to-date, providing reliable data for security, reporting, and
                operational purposes.
              </p>
            </div>
            <div className="content mt-5">
              <h5 className="title">
                Is the system available for group visits?
              </h5>
              <p className="w-100 mb-3">
                Absolutely! For group visits, the organizer can pre-register the
                entire group, making the process faster for everyone.
              </p>
            </div>
            <div className="content mt-5">
              <h5 className="title">Do I need to check out when I leave?</h5>
              <p className="w-100 mb-3">
                Yes, we encourage all visitors to check out when leaving. This
                helps us maintain accurate records and ensures everyone’s
                safety.
              </p>
            </div>
            <div className="content mt-5">
              <h5 className="title">
                Who can access the employee list in the management system?
              </h5>
              <p className="w-100 mb-3">
                Only authorized personnel with the necessary access permissions
                can view the employee list in the system. This ensures that
                sensitive information is protected and visible only to those who
                need it for management purposes.
              </p>
            </div>
            <div className="content mt-5">
              <h5 className="title">Who can add a new user to the system?</h5>
              <p className="w-100 mb-3">
                In our system, only authorized personnel can manage user
                accounts. They have the ability to view the user list, add new
                users, assign roles based on their responsibilities, and delete
                users when necessary. This ensures that access to the system is
                properly controlled and managed to maintain security and
                efficiency.
              </p>
            </div>
            <div className="content mt-5">
              <h5 className="title">Who has the access to download reports?</h5>
              <p className="w-100 mb-4">
                In our system, only authorized personnel who have access to
                reports can download them. There are three types of reports
                available:
              </p>
              <ul className="mb-4" style={{ lineHeight: "1.5rem" }}>
                <li>Visitor Report</li>
                <li>Pre-Registration Report</li>
                <li>Attendance Report</li>
              </ul>
              <p className="w-100 mb-3">
                Authorized users can download reports for specific date ranges
                by selecting the desired "From" and "To" dates, allowing them to
                generate reports for any particular time period.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitorFaqDetails;
