import React, { useRef, useState } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import { db, storage } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Alert from "react-bootstrap/Alert";
import seoData from "../seoData/Consultancy.json";

const Consultancy = () => {
  const form = useRef();
  const form2 = useRef();

  const [formData, setFormData] = useState({
    name: "",
    mobile: "", 
    email: "",
    resume: "",
  });
  const [formData2, setFormData2] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });
  const [result, showresult] = useState(false);
  const [result2, showresult2] = useState(false);
  const [loading, setLoading] = useState();
  const [obj, setObj] = useState();

  const sendEmail = async (e) => {
    e.preventDefault();

    const ref = collection(db, "consultancy");
    await addDoc(ref, {
      ...formData,
    });
    setFormData({
      name: "",
      mobile: "",
      email: "",
      resume: "",
    });
    setObj();
    showresult(true);
    
  setTimeout(() => {
    showresult(false);
  }, 10000);

  };
  const sendEmail2 = async (e) => {
    e.preventDefault();

    const ref = collection(db, "consultancyMessage");
    await addDoc(ref, {
      ...formData2,
    });
    setFormData2({
      name: "",
      mobile: "",
      email: "",
      message: "",
    });
    showresult2(true);
    setTimeout(() => {
      showresult2(false);
    }, 10000);
  

  };


  const uploadResume = (e) => {
    setLoading(true);
    try {
      var storageRef = ref(storage, `images/${e.target.files[0].name}`);
      var uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFormData({ ...formData, resume: url });
            setObj(e.target.files[0].name);
            if (url) {
              setLoading(false);
            } else {
              setLoading(false);
            }
          });
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const Result = () => {
    return (
      <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
      </Alert>
    );
  };
  const Result2 = () => {
    return (
      <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
      </Alert>
    );
  };

  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Consultancy" page="Consultancy" />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <p>
                Our consultancy services are not just limited to business ideas
                and execution but also dedicated resources. We have a
                considerable number of leads and data to help you get the best
                resources with relevant experience and at competent rates.
              </p>
              <p>
                We are committed to providing dedicated resources on-demand. Our
                resources are qualified and experienced possessing the exposure
                to work efficiently and smoothly in remote teams efficiently.
                The resources are open for interview and the screening process,
                they are available to join immediately for remote positions on
                Contract-to-Hire basis.
              </p>
              <p>
                We also have resources possessing the H1B Visa exclusively to
                serve in the USA for both remote and on-site positions.
              </p>
              <p>
                We aim to provide the best and clear information to our clients.
                We believe that providing the best insights to the client will
                help them gain clarity. Many times, the clients have an idea but
                they don’t have that technical knowledge which results in either
                dropping the idea or adopting paths that delays their success
                sometimes. We look forward to helping the clients decide the
                technological aspects for the execution of their idea and about
                the technological innovations.
              </p>
              <p>
                We intend to provide the best information and solutions. If
                you’re confused or don't have the technical know-how or you have
                an idea and a desire but not the roadmap, visit us. We promise
                you, we’ll provide a simple, viable and hassle free solution.
                Feel free to contact us or else show up at our office, we’ll
                discuss it over a coffee with some examples and live projects.
              </p>
              <p>
                Or else, fill in the details in the form below, we’ll get back
                to you asap.
              </p>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <div className="bg-white">
          <div className="container">
            <div className="row pb-5">
              <div className="col-lg-6 mx-auto">
                <div className="contact-form-box shadow-box mb--100">
                  <form
                    className="axil-contact-form"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <p className="text-center">Looking for remote positions, fill-in this form :</p>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="contact-name"
                        required
                        value={formData.name}
                        onChange={(e) => {
                          setFormData({ ...formData, name: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="contact-email"
                        required
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Contact No.</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="contact-phone"
                        required
                        value={formData.mobile}
                        onChange={(e) => {
                          setFormData({ ...formData, mobile: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group mb--40">
                      <label>Drop your CV</label>
                      <div className="fileUpload">
                        <input
                          type="file"
                          className="form-control fileUpload-inp"
                          name="contact-file"
                          onChange={(e) => uploadResume(e)}
                        />
                        <div className="fileUpload-mes">
                          Drag & Drop Files Here
                          <span>&nbsp;Browse Files</span>
                        </div>
                      </div>
                      {obj && <p>{obj} file uploaded</p>}
                    </div>
                    <div className="form-group">
                      <br className="d-none d-lg-block" />
                      <br className="d-none d-lg-block" />
                      <br />
                      <button
                        type="submit"
                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                        name="submit-btn"
                        disabled={loading}
                      >
                        {loading ? "Please wait" : "Submit"}
                      </button>
                    </div>
                    <div className="form-group">
                      {result ? <Result /> : null}
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 mx-auto">
                <div className="contact-form-box shadow-box mb--100">
                  <form
                    className="axil-contact-form"
                    ref={form2}
                    onSubmit={sendEmail2}
                  >
                    <p className="text-center">Looking for consultation for your business, fill-in this form :</p>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="contact-name"
                        required
                        value={formData2.name}
                        onChange={(e) => {
                          setFormData2({ ...formData2, name: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="contact-email"
                        required
                        value={formData2.email}
                        onChange={(e) => {
                          setFormData2({ ...formData2, email: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Contact No.</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="contact-phone"
                        required
                        value={formData2.mobile}
                        onChange={(e) => {
                          setFormData2({ ...formData2, mobile: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group mb--40">
                      <label>How can we help you?</label>
                      <textarea
                        className="form-control"
                        name="contact-message"
                        rows="4"
                        value={formData2.message}
                        onChange={(e) => {
                          setFormData2({ ...formData2, message: e.target.value });
                        }}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                        name="submit-btn"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="form-group">
                      {result2 ? <Result2 /> : null}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Consultancy;
