import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import seoData from "../seoData/TermsOfUse.json"

const TermsOfUse = () => {
  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Terms Of Use" page="Terms Of Use" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <p>
                    ITCode Infotech (abbreviation - IIPL) maintains and hosts
                    this webwebsite as a platform to exhibit information about
                    its services and products.
                  </p>
                  <p>
                    Your access and usage of the website is subject to the
                    following terms and conditions and future revisions if any,
                    (“Terms and Conditions”) and all applicable laws. By
                    accessing and browsing this website, you agree to the
                    following terms & conditions.
                  </p>
                  <ul>
                    <li>
                      The website, including all the content, information,
                      software, products and services provided on the website,
                      are open on an “as it is” and “as per availability” basis.
                      ITCode Infotech expressly disclaims all warranties of any
                      kind, whether express or implied, including, but not
                      limited to, the implied warranty of merchantability,
                      fitness for a particular purpose and non-infringement.
                    </li>
                    <li>
                      The content and the projects described or displayed on the
                      website are subject to change without notice. Copyright
                      may exist on any future materials. Unauthorized recording
                      or use of such materials would be considered infringement
                      of the applicable TRIPS.
                    </li>
                    <li>
                      ITCode Infotech assumes no responsibility, and shall not
                      be liable for, any damage to, or viruses that may infect
                      your system, equipments or any other assets on account of
                      your access to, use of or browsing this website or your
                      downloading of any materials, data, text, images, video,
                      or audio from this website.
                    </li>
                    <li>
                      The materials you see on the website either belong to
                      ITCode Infotech or were used with their consent. Unless
                      otherwise expressly permitted by these Terms and
                      Conditions or with ITCode Infotech's prior written
                      consent, you are not permitted to use these materials
                      yourself or on behalf of anyone else. Any unauthorized use
                      of the photographs could be against copyright, trademark,
                      privacy, and publicity laws.
                    </li>
                    <li>
                      Accessing any other website, or one that is connected to
                      or similar to ours but not under the control of the
                      company is the sole responsibility of the user and not the
                      company, browse at your own risk. ITCode Infotech may
                      offer links on the website to other websites, their
                      inclusion is merely for your convenience and shouldn't be
                      seen as an approval of the website's owner or sponsor of
                      the information found there. With the exception of the
                      Non-Excludable Rights (above), ITCode Infotech makes no
                      representations or warranties, either stated or implied,
                      as to the truth, accuracy, completeness, or legality of
                      any materials or information found on such websites.
                    </li>
                    <li>
                      It is illegitimate to post or send anything that is
                      illegal, threatening, harassing, defamatory, libellous,
                      obscene, pornographic, or profane content, as well as any
                      content that could incite behaviour that would be illegal,
                      give rise to civil disorder, or otherwise violate the law.
                    </li>
                    <li>
                      ITCode Infotech may, at any time revise these Terms and
                      Conditions by updating this post. Since you are bound by
                      these Terms and conditions, please visit this page
                      regularly to learn about the revised Terms and Conditions
                      if any.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TermsOfUse;
