import image1 from "../../assets/image/portfolio/primeship.png";
import image2 from "../../assets/image/portfolio/blackpledge.png";
import image3 from "../../assets/image/portfolio/hmis.png";
import image4 from "../../assets/image/portfolio/3d4kids.png";
import image11 from "../../assets/image/portfolio/tradeunlisted.png";
import image13 from "../../assets/image/portfolio/dskyadmmin.png";
import image14 from "../../assets/image/portfolio/univault.png";
import image15 from "../../assets/image/portfolio/whatismyip.png";
import tradeunlisted1 from "../../assets/image/portfolio/tradeunlisted1.png";
import primeship2 from "../../assets/image/portfolio/primeship2.png";
import primeship3 from "../../assets/image/portfolio/primeship3.png";
import shipmate from "../../assets/image/portfolio/Shipmate.png";
import shipmate1 from "../../assets/image/portfolio/Shipmate2.png";
import shipmate2 from "../../assets/image/portfolio/Shipmate3.png";
import shipmate3 from "../../assets/image/portfolio/Shipmate4.png";
import conciergeclub1 from "../../assets/image/portfolio/conciergeclub5.png";
import conciergeclub2 from "../../assets/image/portfolio/conciergeclub2.png";
import conciergeclub3 from "../../assets/image/portfolio/conciergeclub3.png";
import conciergeclub5 from "../../assets/image/portfolio/conciergeclub1.png";
import koski1 from "../../assets/image/portfolio/koski3.png";
import koski2 from "../../assets/image/portfolio/koski2.png";
import koski3 from "../../assets/image/portfolio/koski1.png";
import clothesmnia from "../../assets/image/portfolio/clothesmania1.png";
import clothesmnia1 from "../../assets/image/portfolio/clothesmania2.png";
import clothesmnia2 from "../../assets/image/portfolio/clothesmania3.png";
import Goodera1 from "../../assets/image/portfolio/goodera1.png";
import Goodera2 from "../../assets/image/portfolio/goodera2.png";
import Goodera3 from "../../assets/image/portfolio/goodera3.png";
import Goodera4 from "../../assets/image/portfolio/goodera4.png";
import blinds1 from "../../assets/image/portfolio/blinds1.png";
import blinds2 from "../../assets/image/portfolio/blinds2.png";
import blinds3 from "../../assets/image/portfolio/blinds3.png";
import blinds4 from "../../assets/image/portfolio/blinds4.png";
import diakraff1 from "../../assets/image/portfolio/diakraff1.png";
import diakraff2 from "../../assets/image/portfolio/diakraff2.png";
import diakraff3 from "../../assets/image/portfolio/diakraff3.png";
import diakraff4 from "../../assets/image/portfolio/diakraff4.png";
import inkppt1 from "../../assets/image/portfolio/inkppt1.png";
import inkppt2 from "../../assets/image/portfolio/inkppt2.png";
import inkppt3 from "../../assets/image/portfolio/inkppt3.png";
import inkppt4 from "../../assets/image/portfolio/inkppt4.png";
import upsqode1 from "../../assets/image/portfolio/upsqode3.png";
import upsqode2 from "../../assets/image/portfolio/upsqode1.png";
import upsqode3 from "../../assets/image/portfolio/upsqode2.png";
import upsqode4 from "../../assets/image/portfolio/upsqode4.png";
import Fearless1 from "../../assets/image/portfolio/Fearless1.png";
import Fearless2 from "../../assets/image/portfolio/Fearless2.png";
import Fearless3 from "../../assets/image/portfolio/Fearless3.png";
import Fearless4 from "../../assets/image/portfolio/Fearless4.png";
import dsky2 from "../../assets/image/portfolio/d-sky2.png";
import dsky3 from "../../assets/image/portfolio/d-sky3.png";
import dsky4 from "../../assets/image/portfolio/d-sky4.png";
import certificate1 from "../../assets/image/portfolio/My certificate.png";
import certificate2 from "../../assets/image/portfolio/Get started.png";
import certificate3 from "../../assets/image/portfolio/Certificate Maker.png";
import certificate4 from "../../assets/image/portfolio/Portrait.png";
import certificate5 from "../../assets/image/portfolio/Landscape.png";
import certificate6 from "../../assets/image/portfolio/My certificate.png";
import newreassiace1 from "../../assets/image/portfolio/newrenaissance1.png";
import newreassiace2 from "../../assets/image/portfolio/newrenaissance2.png";
import newreassiace3 from "../../assets/image/portfolio/newrenaissance3.png";
import newreassiace4 from "../../assets/image/portfolio/newrenaissance4.png";
import newreassiace5 from "../../assets/image/portfolio/newrenaissance5.png";
import vendor1 from "../../assets/image/portfolio/Login.png";
import vendor2 from "../../assets/image/portfolio/Dashboard.png";
import vendor3 from "../../assets/image/portfolio/Listing View _ List.png";
import vendor4 from "../../assets/image/portfolio/Listing View.png";
import vendor5 from "../../assets/image/portfolio/Add a listing.png";
import reseller1 from "../../assets/image/portfolio/reseller1.png";
import reseller2 from "../../assets/image/portfolio/reseller2.png";
import reseller3 from "../../assets/image/portfolio/reseller3.png";
import reseller4 from "../../assets/image/portfolio/reseller4.png";
import reseller5 from "../../assets/image/portfolio/reseller5.png";
import dskyretailer1 from "../../assets/image/portfolio/dskyretailer1.1.png";
import dskyretailer2 from "../../assets/image/portfolio/dskyretailer2.1.png";
import dskyretailer3 from "../../assets/image/portfolio/dskyretailer3.1.png";
import dskyretailer4 from "../../assets/image/portfolio/dskyretailer4.webp";
import dskycashcollector1 from "../../assets/image/portfolio/dskycashcollector1.1.png";
import dskycashcollector2 from "../../assets/image/portfolio/dskycashcollector2.1.png";
import dskycashcollector3 from "../../assets/image/portfolio/dskycashcollector3.1.png";
import dskycashcollector4 from "../../assets/image/portfolio/dskycashcollector4.webp";
import fracassets1 from "../../assets/image/portfolio/fracassets.png";
import fracassets2 from "../../assets/image/portfolio/fracassets1.png";
import fracassets3 from "../../assets/image/portfolio/fracassets2.png";
import fracassets4 from "../../assets/image/portfolio/fracassets3.png";
import fracassets5 from "../../assets/image/portfolio/fracassets4.png";
import fracassets6 from "../../assets/image/portfolio/fracassets5.png";
import tradeunlisted2 from "../../assets/image/portfolio/tradeunlisted2.png";
import tradeunlisted3 from "../../assets/image/portfolio/tradeunlisted3.png";
import tradeunlisted4 from "../../assets/image/portfolio/tradeunlisted4.png";
import univault1 from "../../assets/image/portfolio/univault1.png";
import univault2 from "../../assets/image/portfolio/univault2.png";
import univault3 from "../../assets/image/portfolio/univault3.png";
import univault4 from "../../assets/image/portfolio/univault4.png";
import whatismyip1 from "../../assets/image/portfolio/whatismyip.png";
import whatismyip2 from "../../assets/image/portfolio/whatismyip1.png";
import whatismyip3 from "../../assets/image/portfolio/whatismyip2.png";
import whatismyip4 from "../../assets/image/portfolio/whatismyip3.png";
import dskyhtml from "../../assets/image/portfolio/dskyhtml.png";
import dskyhtml1 from "../../assets/image/portfolio/dskyhtml1.png";
import dskyhtml2 from "../../assets/image/portfolio/dskyhtml2.png";
import dskyhtml3 from "../../assets/image/portfolio/dskyhtml3.png";
import hmis1 from "../../assets/image/portfolio/hmis1.png";
import hmis2 from "../../assets/image/portfolio/hmis2.png";
import hmis3 from "../../assets/image/portfolio/hmis3.png";
import hmis4 from "../../assets/image/portfolio/hmis4.png";
import kids1 from "../../assets/image/portfolio/kidsangular1.png";
import kids2 from "../../assets/image/portfolio/kidsangular2.png";
import kids3 from "../../assets/image/portfolio/kidsangular3.png";
import kids4 from "../../assets/image/portfolio/kidsangular4.png";



export const ProjectData = [
    {
      id: 1,
      image1: [image1, primeship2, primeship3],
      img: image1,
      title: "Primeship",
      category: ["Angular"],
      excerpt:
        "The appeal of mobile apps for its market potential cannot fade away anytime soon, at least until another great technology can replace mobile apps.",
      body: [
        "Mobile apps have already penetrated every sphere of our lives and every imaginable business niche. Naturally, the appeal of mobile apps for its market potential cannot fade away anytime soon, at least until another great technology can replace mobile apps. development company and its business clients. . So, what about the failure of other apps? What are the key factors responsible for the failure of millions of apps?",
        "Front-end development essentially focuses and works on the visual perceptions of a website. It’s about the look, load time, pattern and functionality of the components of a website. Front-End is a mix of programming and layout that powers the visuals and interactions on the web. ",
        "We provide end-to-end website development services designed to bring your vision to life. Whether you need a simple landing page, an eCommerce store, or a full-scale web application, we have the expertise to deliver it all. From front-end design that grabs attention to robust back-end development that ensures your site runs smoothly, we cover every aspect of website creation. Our team of skilled developers and designers collaborates closely with you to understand your goals and create a site that is both functional and aesthetically pleasing.",
        "We understand that every business has unique needs and budgets. That’s why we offer a range of website development packages tailored to different price points. Whether you’re a startup looking to build an online presence or an established business wanting to upgrade your site, we have cost-effective solutions that don’t compromise on quality. With competitive pricing and transparent processes, you can trust that your website will be built within budget while still maintaining high standards of performance and design.",
        "We utilize the latest technologies and frameworks to ensure your website performs optimally across all devices. From responsive web design to fast load times and enhanced security features, we stay ahead of the curve in the rapidly evolving world of web development. Our developers specialize in modern technologies like React, Angular, Node.js, and more, ensuring that your site is built using the best tools available. This guarantees not only great performance but also scalability, so your site can grow as your business expands.",
        "In today's mobile-driven world, having a website that functions seamlessly on smartphones and tablets is crucial. We prioritize mobile responsiveness, ensuring that your site offers an excellent user experience across all screen sizes. Whether it's optimizing layouts, improving load times, or ensuring that your content is easily accessible, we make sure your site is mobile-friendly. This helps you reach a broader audience and improves your site’s search engine rankings.",
        "Our focus goes beyond just creating visually appealing websites—we also ensure that your site is optimized for a smooth and intuitive user experience. From quick load times to easy navigation, we build websites that keep your visitors engaged. We also integrate essential features like contact forms, live chat, and eCommerce functionalities to create a seamless interaction for your customers. You’re not just getting a website; you’re getting a platform that drives conversions and enhances your online presence.",
        "Having a stunning website is not enough if people can't find it. That’s why we offer professional SEO services as part of our development package. Our SEO experts ensure that your website is fully optimized for search engines from day one, helping you rank higher in search results and attract more organic traffic. From keyword integration to meta tags and site performance improvements, we handle all the technical aspects of SEO so you can focus on growing your business.",
        "Ready to take your online presence to the next level? Whether you need a brand-new website or want to improve your existing one, we have the solutions you need. Contact us today to learn more about our services and how we can help you create a website that stands out from the competition.",
      ],
    },
    {
      id: 2,
      image1: [image2, primeship2, primeship2],
      img: image2,
      title: "Black Pledge",
      category: ["Angular"],
      excerpt:
        "We build our computer (systems) the way we build our cities: over time, without a plan, on top of ruins.",
      body: [
        "The project is entirely client-based, as I must work with Angular & Datastore should be in Firebase For the media. This is the leading page is for the app how to use it & more information for users.",
        "This project focuses on creating a fully client-based application using Angular for the front-end and Firebase as the primary datastore, particularly for handling media content. The app is designed with ease of use in mind, aiming to provide users with a seamless experience. It is built on a robust Angular framework that ensures fast performance, dynamic content rendering, and modular development, making it easier to maintain and scale over time. Firebase, known for its real-time database capabilities, serves as the backend solution to store and manage media files efficiently.",
        "The main goal of the application is to offer users an intuitive platform where they can interact with media content, such as images or videos, while maintaining secure and fast data access. Firebase offers various features like real-time synchronization, cloud storage, and security rules, ensuring the media content is always available and safe.",
        " The app’s leading page is dedicated to providing users with comprehensive guidance on how to use the app. Designed with clarity and simplicity, it walks users through every feature, ensuring they can make the most of the platform. The interface is responsive and adaptive, ensuring that it works seamlessly on both desktop and mobile devices.",
        "One of the core functionalities of this app is media storage and retrieval. With Firebase's cloud storage, users can upload, retrieve, and manage media files quickly. Firebase ensures that the media content is securely stored and can be accessed from anywhere with an internet connection.",
        "Firebase's real-time database integration allows users to receive instant updates without refreshing the app. This is particularly useful for media content that may change frequently. Users will see updates to images, videos, or other forms of media in real-time, ensuring they always have access to the latest content.",
        "The app will integrate Firebase Authentication to manage user sign-ups, logins, and sessions. This allows users to securely create accounts and access personalized media collections. Google, Facebook, and email-based logins are supported, making it convenient for users to sign in through their preferred platforms.",
        "Firebase Security Rules will be implemented to ensure that users’ data and media are safe. These rules restrict unauthorized access to files and data, ensuring only authenticated users or specific permission holders can view or manipulate media content.",
        "Leveraging Angular’s component-based architecture and Firebase’s serverless model, the app is highly scalable. As the number of users and media files grows, Firebase handles scaling effortlessly without needing extensive server infrastructure.",
        "This client-based app offers a modern, intuitive platform for users to interact with media content in a secure and efficient way. Built with Angular and Firebase, the app leverages the strengths of these technologies to provide real-time updates, scalability, and robust security. Whether it's uploading media, viewing content, or learning how to use the platform, users are guaranteed a smooth and engaging experience from start to finish.",
      ],
    },
    {
      id: 3,
      image1: [image3, hmis1, hmis2, hmis3, hmis4],
      img: image3,
      title: "HMIS",
      category: ["Angular"],
      excerpt:
        "We build our computer (systems) the way we build our cities: over time, without a plan, on top of ruins.",
      body: [
        "The project is entirely client-based, as I must work with Angular & Datastore should be in MongoDB (NoSQL). It’s a Fully Angular base E-commerce site.",
        "The Health Management Information System (HMIS) is an integrated web-based platform designed to streamline healthcare operations and provide real-time insights into patient demographics, drug management, and clinical workflows. The system is geared towards efficiently managing health records for both adult and child patients, with further categorization by gender to provide detailed population health statistics.",
        "The HMS tracks the number of patients by categorizing them into two major groups: Adults and Children. This allows healthcare providers to understand the population distribution and tailor services accordingly. The system further refines this data into Male and Female groups, giving a clear demographic picture. These classifications aid in targeted care, ensuring that the facility meets the specific healthcare needs of different patient segments, such as maternal health services for females or pediatric care for children.",
        "A core feature of the HMS is its Consulted Patient Tracking. It records details about all patient consultations, capturing key information about the nature of the visit, the consulting physician, and the services provided. This functionality not only helps streamline day-to-day operations but also enhances the continuity of care by ensuring that all patient data is centralized and easily accessible for follow-ups.",
        "The system includes robust Drug Management features, which track the availability and dispensation of medications. This includes a detailed record of Essential Drugs—those that are critical to patient care and must be maintained in stock at all times. Additionally, the system monitors Drugs Prescribed by doctors and ensures that they are appropriately dispensed to patients, capturing all necessary information about dosages and refills.",
        "The HMS also tracks Drugs Dispensed, ensuring that all prescriptions are fulfilled correctly, and that drug inventory is updated in real-time. This reduces the likelihood of stockouts and ensures that essential medications are always available for patients.",
        "Efficient inventory management is critical in healthcare, and the HMS incorporates features to manage both Orders and Indents Raised. Orders placed for drugs, medical supplies, and other resources are tracked in the system to maintain a record of incoming stock. In parallel, Indent Raised functions allow healthcare personnel to flag the need for specific drugs or supplies that are running low, ensuring that shortages are prevented before they occur. This system of checks and balances helps manage the procurement and distribution of essential medical resources.",
        "The HMS simplifies the process of managing Prescriptions, ensuring that all patient data related to their treatment plan is stored in a secure and accessible manner. Once a doctor consults a patient and issues a prescription, it is tracked in the system to facilitate easy retrieval during follow-up visits. The system ensures that all prescribed medications are accurately Dispensed to patients, creating a seamless link between clinical consultation and pharmaceutical services.",
        "The reporting capabilities of the HMS are essential for administrative and operational purposes. It provides detailed insights into the number of Consulted Patients, including breakdowns by age and gender. Additionally, reports on Drug Usage, Prescription Patterns, and Inventory Levels help healthcare managers ensure that the facility operates efficiently and that resources are optimally used. The system also generates reports on Orders and Indents, providing insights into procurement trends and stock levels.",
        "This Health Management System offers an all-encompassing solution to healthcare management, from tracking patient demographics to managing drug inventories and streamlining consultation workflows. It is designed to provide healthcare professionals with the tools they need to deliver quality care efficiently and effectively.",
      ],
    },
    {
      id: 4,
      image1: [image4, kids1, kids2, kids3, kids4],
      img: image4,
      title: "3d4 kids",
      category: ["Angular"],
      excerpt:
        "Front-end development essentially focuses and works on the visual perceptions of a website. It’s about the look, load time, pattern and functionality of the components of a website. Front-End is a mix of programming and layout that powers the visuals and interactions on the web.",
      body: [
        // "Mobile apps have already penetrated every sphere of our lives and every imaginable business niche. Naturally, the appeal of mobile apps for its market potential cannot fade away anytime soon, at least until another great technology can replace mobile apps. development company and its business clients. . So, what about the failure of other apps? What are the key factors responsible for the failure of millions of apps?",
        // "Front-end development essentially focuses and works on the visual perceptions of a website. It’s about the look, load time, pattern and functionality of the components of a website. Front-End is a mix of programming and layout that powers the visuals and interactions on the web.",
        "3d4 kids helps schools optimize the delivery of their educational content. Schools can efficiently design and customize their curriculums using the platform, ensuring that learning objectives are met more effectively.",
        "The platform focuses on delivering efficient learning outcomes, offering tools that allow teachers to better engage with students. This not only improves classroom learning but also extends the educational experience beyond school hours.",
        "3d4 kids fosters collaboration between parents, teachers, and students, creating a strong, interactive learning community. Parents are kept in the loop with real-time updates on their child's progress, enabling them to support their children in more informed ways.",
        "With real-time access to data, parents, teachers, and school administrators can have an accurate and complete view of a student’s learning history, helping them make evidence-based, timely decisions.",
        "The platform allows schools to create personalized learning journeys that engage students at every step, ensuring that the curriculum adapts to individual learning needs even after school hours.",
        "Schools can generate progressive reports for individual students, classes, and overall school performance. These reports offer valuable insights, including cohort reports for regions, providing an in-depth analysis of learning trends.",
        "Parents can access reports in multiple languages, making it easier for diverse communities to stay informed about their child’s educational journey. The subscription also includes features like student reports, school performance insights, and personalized alerts.",
        "The platform offers a marketplace where parents can find enrichment services like home tuition, after-school programs, and other learning opportunities that complement school-based education.",
        "3d4 kids is designed to empower both schools and parents by making the learning process transparent and accessible. Parents have access to tools that allow them to track their child's academic journey in real-time, providing a comprehensive understanding of their child’s performance and areas for improvement. Teachers can focus more on personalized learning strategies, thanks to the easy-to-use administrative tools that streamline the classroom management process.",
        "The platform offers flexibility through its data-driven approach, allowing schools to tailor learning solutions to fit their unique environment. Teachers and administrators can customize curriculums, assessments, and performance reports to meet the goals of individual students, classrooms, and entire schools.",
        "3d4 kids also includes robust agreements with educational partners, ensuring schools and parents have access to top-tier resources and services. The platform provides strong customer care and support, ensuring users have a seamless experience. Support services are available for technical issues, policy clarifications, and overall guidance, ensuring both parents and schools can make the most out of the platform.",
        "3d4 kids provides a comprehensive and customizable digital ecosystem for managing the complexities of a child's learning journey. Its user-centric design benefits students by engaging parents and teachers, allowing for better decision-making, enhanced learning outcomes, and a more connected educational experience.",
      ],
    },
    {
      id: 16,
      image1: [dskyhtml, dskyhtml1, dskyhtml2, dskyhtml3],
      img: dskyhtml,
      title: "D-SKY",
      category: ["HTML/CSS"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
        "At the forefront of D-SKY’s offering is its commitment to security. The website employs robust security protocols to guarantee that each generated e-pin is genuine and safe. Users can recharge with confidence, knowing that their transactions are protected against fraud.",
        "Understanding the diverse needs of its users, the D-SKY website offers customizable options for e-pin generation. Users can easily select their preferred carrier, specify the amount, and choose the quantity of pins they wish to generate. This flexibility ensures that the service meets various requirements, whether for personal use or retail purposes.",
        "The D-SKY website caters to a wide range of networks, allowing users to generate e-pins for various service providers. This versatility ensures that users have the freedom to recharge their accounts with their preferred carrier, enhancing the convenience of the service.",
        "Designed with simplicity in mind, the D-SKY website features an intuitive layout that makes it easy for anyone to navigate. Users can quickly access the e-pin generation tools and complete their tasks with just a few clicks. The clear navigation and organized sections enhance the overall user experience, allowing for efficient e-pin management.",
        "Retailers and frequent users benefit from D-SKY's streamlined commission management system. The website allows users to monitor their commission balance easily, providing insights into earnings and facilitating reinvestment in more e-pins. This feature supports effective financial management and operational efficiency.",
        "Users can securely store their generated e-pins within the D-SKY website, ensuring that they are easily accessible whenever needed. The site also offers options to export e-pins via SMS or to print them directly. This functionality provides users with flexible ways to utilize their generated e-pins, whether for personal use or distribution.",
        "The D-SKY Retailer website redefines the way users generate and manage e-pins for quick recharges. By providing a secure, customizable, and user-friendly platform, D-SKY enhances the recharge experience for individuals and retailers alike. Its commitment to security, combined with features like multiple network support and efficient commission management, makes it an invaluable tool in the world of mobile connectivity. As users navigate the D-SKY website, they can expect a seamless experience that empowers them to take control of their e-pin needs. Join the D-SKY community today and discover the future of efficient mobile recharges, all from the convenience of your web browser.",
      ],
    },
    {
      id: 8,
      image1: [dskyretailer1, dskyretailer2, dskyretailer3],
      img: dskyretailer1,
      title: "D-sky Retailer",
      category: ["Ionic"],
      excerpt: "Ecommerce isn’t the cherry on the cake, it’s the new cake",
      body: [
        "Because Ionic is required for the app, the project is fully client-based. Both apps must be available, one on Google Play and the other on Apple’s App Store. For your convenience, I’ve put all of the links below the image.",
        "Security is paramount when dealing with electronic transactions, and D-SKY takes this seriously. The app guarantees that every generated e-pin is genuine and safe, providing peace of mind to users. The built-in security protocols ensure that your transactions are protected from any potential fraud or misuse.",
        "D-SKY understands that every user has unique needs. The app offers customizable options that allow you to tailor the e-pin generation process to your specific requirements. Users can easily adjust the carrier, amount, and quantity of the pins, enabling a personalized experience that meets varying demands.",
        "Versatility is a key strength of D-SKY. The app supports a wide range of networks, allowing users to generate e-pins across different service providers. This flexibility ensures that you can recharge with the carrier of your choice, streamlining your mobile connectivity.",
        "D-SKY is designed with the user in mind. Its intuitive interface makes the app accessible to everyone, regardless of their technical expertise. With just a few taps, users can select their preferred settings and generate e-pins swiftly. The straightforward navigation ensures that you spend less time figuring out the app and more time enjoying its benefits.",
        "For retailers and frequent users, managing commission balance is crucial. D-SKY offers efficient tools for handling your commission and wallet balance, making it easy to track earnings and reinvest in purchasing more e-pins. This functionality helps users maximize their productivity and financial management.",
        "The app features secure storage for all generated e-pins, allowing users to manage their pins safely within the application. Additionally, D-SKY offers the capability to export e-pins via SMS to contacts or print them out, providing further convenience in accessing and utilizing the generated e-pins.",
        "D-SKY Retailer stands out as a comprehensive solution for generating e-pins quickly and securely. With features that cater to the needs of both individual users and retailers, the app provides a reliable platform for mobile recharges. Its user-friendly design, customizable options, and strong security measures make it a preferred choice in the e-pin generation space. Whether you are looking to manage your commission or simply need a quick way to recharge, D-SKY is your one-stop solution for all your e-pin needs. Embrace the future of mobile connectivity with D-SKY Retailer, and enjoy the seamless experience of generating and managing your e-pins effortlessly.",
      ],
    },
    {
      id: 9,
      image1: [
        dskycashcollector1,
        dskycashcollector2,
        dskycashcollector3,
      ],
      img: dskycashcollector1,
      title: "D-SKY Cash Collector",
      category: ["Ionic"],
      excerpt:
        "we will convert jpg to single page responsive site with all the device show user friendly design.",
      body: [
        "Mobile apps have already penetrated every sphere of our lives and every imaginable business niche. Naturally, the appeal of mobile apps for its market potential cannot fade away anytime soon, at least until another great technology can replace mobile apps.",
        "Retailers can place real-time cash collection requests directly through the app, ensuring that representatives receive immediate notifications. The app provides crucial details such as collection location, the amount to be collected, and the requested time, allowing representatives to prioritize and manage their workload effectively.",
        "Transparency and communication are at the heart of D-SKY Cash Collector. Representatives can update the status of each collection task in real-time, allowing stakeholders to stay informed about the progress of their collections. Tasks can be marked as 'Pending' 'Assigned', 'Collected', or 'Payment Received'. This feature helps reduce confusion and ensure smooth communication between collection teams, retailers, and companies.",
        "The app offers access to comprehensive reports that summarize collection activities. These reports include key details such as the amounts collected, timestamps for each activity, and the client information for every transaction. This ensures proper documentation and easy reference for future audits or analysis. The feature supports both cash collectors and management in monitoring performance and verifying collections.",
        "With the D-SKY Cash Collector, missing an appointment or deadline becomes a thing of the past. The app includes an intelligent notification system that sends alerts for upcoming collection appointments, deadline reminders, and any urgent updates. This feature helps cash collection representatives stay organized and ensures that they meet their commitments on time.",
        "By automating much of the cash collection workflow, D-SKY Cash Collector simplifies the process for both companies and representatives. Real-time status updates and notifications ensure that everyone is on the same page, leading to fewer misunderstandings or delays.",
        "The app is designed to optimize the daily routines of cash collection representatives. By providing all necessary information (collection details, client data, etc.) in one place, the app saves valuable time, allowing representatives to focus on completing tasks more efficiently.",
        "Communication between stakeholders is greatly improved through the app's real-time status updates. Retailers, managers, and representatives can all see the current state of a collection request, reducing the need for constant phone calls or check-ins.",
        "The app ensures that cash collection is handled securely by keeping detailed logs of every transaction. These logs serve as a record of when and where cash was collected, improving accountability and reducing the risk of errors.",
        "The app's ease of use and transparency lead to higher satisfaction for both the cash collection representatives and the retailers. Retailers can trust that their requests are being handled promptly and that their payments will be processed efficiently.",
        "The D-SKY Cash Collector is an essential tool for businesses that rely on field cash collections. It streamlines the entire cash collection process, offering real-time updates, detailed reports, and intelligent notifications. By empowering cash collection representatives with this app, companies can increase productivity, improve communication with stakeholders, and ensure secure cash handling, ultimately leading to enhanced customer satisfaction.",
      ],
    },
    {
      id: 11,
      image1: [
        image11,
        tradeunlisted1,
        tradeunlisted2,
        tradeunlisted3,
        tradeunlisted4,
      ],
      img: image11,
      title: "TradeUnlisted",
      category: ["React"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        "In this site we will make animated web Design to make Awesome look. this site is fully responsive site.",
        "Unlisted companies typically operate under the radar of traditional stock markets. These companies are in various stages of development, with some still in their early phases, while others are well-established yet have not opted to list on public exchanges. TradeUnlisted facilitates access to such companies, allowing investors to buy their shares before they become publicly traded, in what is known as the Pre-IPO stage. These companies often seek to grow rapidly and aim to turn into market leaders in their respective sectors. As they expand, they offer early investors the opportunity to reap significant financial gains.",
        "For investors, this presents a chance to get in on the ground floor of high-potential ventures. Many unlisted companies eventually make their way to public stock exchanges through an Initial Public Offering (IPO), and early shareholders stand to benefit from this transition, with the potential for the stock price to surge. TradeUnlisted serves as a bridge between these companies and investors, offering a regulated, transparent, and reliable platform to facilitate such transactions.",
        "TradeUnlisted has been credited with bringing structure and credibility to the Pre-IPO and Unlisted Market in India. Historically, investing in unlisted companies was considered risky and accessible primarily to seasoned investors. However, the platform has played a pivotal role in institutionalizing this segment of the market, making it more accessible to retail and institutional investors alike.",
        "Through TradeUnlisted, the Pre-IPO and Unlisted Market in India has seen significant growth, providing a structured marketplace where investors can make informed decisions. The platform works closely with a network of global investment managers who specialize in identifying high-growth potential companies. This collaboration ensures that investors are presented with vetted, promising opportunities in sectors ranging from technology and pharmaceuticals to fintech and consumer goods.",
        "One of the standout features of TradeUnlisted is its close working relationship with global investment managers. These professionals, based in India and abroad, are constantly on the lookout for emerging companies with high growth potential. By partnering with these managers, TradeUnlisted is able to offer its users access to exclusive opportunities that might otherwise remain hidden.",
        "The platform's curated approach ensures that only the most promising unlisted and Pre-IPO companies are featured, helping investors to make more confident investment decisions. TradeUnlisted not only facilitates the buying and selling of shares but also provides insights and market research to help users stay informed about the companies they are investing in.",
        "TradeUnlisted is more than just a marketplace for Pre-IPO and Unlisted Stocks; it is a platform that enables investors to participate in the growth journey of emerging companies. By institutionalizing the unlisted market in India, it has created a reliable, transparent environment where investors can explore and invest in high-potential companies. With its collaborative approach, working with global investment managers, TradeUnlisted continues to lead the way in providing Pre-IPO opportunities that can transform the investment landscape for its users.",
      ],
    },
    {
      id: 12,
      image1: [
        fracassets1,
        fracassets2,
        fracassets3,
        fracassets4,
        fracassets5,
        fracassets6,
      ],
      img: fracassets1,
      title: "FracaAssets",
      category: ["React"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
        "FracAssets is designed to transform the real estate experience, putting customers at the center of its mission. By focusing on accessibility, diversification, and innovation, FracAssets provides customers with more control over their property investments, making it easier and more profitable for a broader range of investors.",
        "One of the standout features of FracAssets is its asset class diversification, which helps customers spread their investments across different types of properties. This means that instead of concentrating all their resources in a single asset—like a city apartment or a piece of farmland—customers can invest in multiple property types, reducing risk and increasing the potential for steady returns. This diversification can provide more stability, as returns from one asset can balance out fluctuations in another, making real estate investment less daunting for customers.",
        "Another way FracAssets benefits customers is through its regular rental yields facilitated by real estate crowdfunding. This innovative approach allows multiple investors to pool their money together to invest in premium properties, which would normally require large sums of capital. In return, they receive consistent rental income without the burdens of managing the property themselves. By enabling smaller, more manageable investments, FracAssets ensures that customers can enjoy the financial benefits of property ownership without the complexities of being a full-time landlord.",
        "FracAssets also makes property investment more accessible with its smaller ticket size. Traditional real estate investments often require substantial upfront capital, which can deter many potential investors. However, FracAssets lowers this barrier, allowing customers to participate in the real estate market with smaller amounts of money. This enables individuals from various financial backgrounds to enter the property market, allowing them to invest in high-quality real estate assets without overextending their finances.",
        "Additionally, the curated investment opportunities offered by FracAssets provide customers with a sense of security and confidence. Every investment option on the platform is handpicked based on its potential for strong returns, meaning customers can invest with the reassurance that each opportunity has been thoroughly vetted. These carefully selected properties help customers maximize their returns while minimizing risk.",
        "FracAssets' deep expertise, with over 160 years of experience in the real estate sector, provides customers with the knowledge and guidance needed to make informed decisions. Whether customers are interested in buying, selling, or renting properties, the platform’s global reach and seasoned advisory team offer invaluable support. This rich legacy ensures that customers can trust FracAssets to navigate the complexities of the real estate market on their behalf.",
        "FracAssets empowers customers to diversify their investments, enjoy regular rental yields through crowdfunding, and invest with smaller amounts of capital. The platform simplifies the real estate investment process, making it more inclusive, reliable, and profitable for all customers, regardless of their investment experience. By placing the customer’s needs first, FracAssets is revolutionizing the way people approach real estate, making it a more favorable and rewarding experience.",
      ],
    },
    {
      id: 13,
      image1: [image13, dsky2, dsky3, dsky4],
      img: image13,
      title: "Dskylink",
      category: ["React"],
      excerpt:
        "In this site we will make animated web Design to make Awesome look.",
      body: [
        "this project is easy to understand project structure and scalable , fast Loaded.",
        "Dskylink is a comprehensive platform designed to streamline various operations related to retailers, cash collectors, pin management, and request assignments. The website provides an intuitive and well-organized dashboard, offering users a clear view of key metrics such as total collection requests, total e-pins added, and the number of e-pins used. This real-time data tracking system allows both administrators and retailers to monitor essential business metrics with ease, enhancing the overall management and productivity of their operations.",
        "The platform includes robust retailer management features, which allow administrators to monitor retailer activity and analyze user behavior over specific periods such as seven days, one month, or one year. This helps track active, inactive, and blocked users within the retail network, offering valuable insights into retailer engagement. Furthermore, Dskylink’s cash collection module is tailored for cash collectors, providing real-time status updates on cash collection activities, requests, and detailed reports. This module ensures that collectors can efficiently manage cash flows while tracking their performance and collection status.",
        "One of Dskylink's standout features is its e-pin management system, which provides retailers and administrators with tools to easily handle e-pins for quick recharge services. With options for e-pin sales, usage tracking, and generation, the platform offers full control over e-pin distribution and sales. Retailers can generate new e-pins for various services, monitor usage, and ensure seamless transactions, making the system highly beneficial for managing prepaid recharge and utility payments.",
        "The user-friendly navigation system in Dskylink includes a side menu that organizes key features such as retailers, cash collectors, request assignments, pin management, reports, and notifications. This logical structure ensures ease of use and quick access to important functionalities, simplifying the management of day-to-day operations. Additionally, the platform includes a powerful reporting feature that allows users to generate detailed reports on activities like cash collection, e-pin usage, and retailer performance. These reports can be filtered by various parameters, helping administrators make informed business decisions.",
        "Dskylink also offers a master section, giving administrative users control over settings, user management, and other critical functions. The 'Commission Booster' feature is designed to motivate retailers, offering them incentives based on e-pin sales and other activities. This enhances retailer engagement and drives platform growth. Furthermore, the platform includes tools for app and website management, ensuring the seamless integration and maintenance of the services provided to users.",
        "Dskylink includes a user feedback section, allowing retailers and cash collectors to share their insights and suggestions. This feedback mechanism helps improve the overall user experience and service quality. In conclusion, Dskylink is a comprehensive solution that simplifies the management of cash collection, e-pin sales, and retailer activities. Its organized dashboard, detailed reporting tools, and intuitive interface make it an essential platform for enhancing operational efficiency and maintaining transparent business processes.",
      ],
    },
    {
      id: 14,
      image1: [image14, univault1, univault2, univault3, univault4],
      img: image14,
      title: "Univault",
      category: ["React"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "Univault is designed to provide users with a seamless, secure platform for managing vaults and liquidity. The platform offers an intuitive dashboard that serves as the central hub for all its functionalities. Whether you're a depositor looking to invest or a vault manager aiming to generate yield through liquidity, Univault provides tools that simplify complex financial operations. Its easy-to-navigate interface allows users to monitor vault performance, view real-time statistics, and connect their wallets to unlock a broader range of features.",
        "For depositors, Univault offers a vast selection of over 1,000 vaults to explore and invest in. These vaults can be sorted by key financial metrics such as assets under management and average monthly returns, making it easier for users to find opportunities that align with their financial goals. Additionally, the platform highlights 'Featured Vaults', offering guidance on top-performing vaults to help users make informed decisions. By providing detailed analytics, Univault ensures depositors have all the information they need to maximize their investment returns in a transparent and user-friendly environment.",
        "Vault managers, on the other hand, can take advantage of Univault’s easy vault creation process. The platform offers a streamlined way to create and manage vaults, allowing users to lend or provide liquidity and earn yield. With an emphasis on user empowerment, Univault reduces the complexity often associated with liquidity management, enabling even novice managers to enter the space with confidence. Vault managers can focus on growing their portfolios while relying on Univault’s secure infrastructure to handle the technical aspects of vault management.",
        "One of the standout features of Univault is its ability to connect with users’ wallets. By integrating wallet connectivity, the platform allows users to explore advanced features like managing assets under management, tracking depositor activity, and participating in liquidity pools. The platform’s 'Connect Wallet' button is prominently displayed, encouraging users to unlock additional functionalities that enhance their experience. This ensures that users are always in control of their funds and have access to real-time data to make informed decisions.",
        "The statistics panel on the dashboard offers real-time insights into the platform’s activity, including the number of active vaults, depositors, and the total value of assets under management. This transparency allows users to monitor the platform’s health and assess its growth potential at a glance. Whether you are a new user or an experienced investor, Univault’s detailed statistics give you a clear picture of how the platform is performing, making it easier to trust and invest in.",
        "In addition to its vault and liquidity management features, Univault also fosters a sense of community through its social media integrations. Users are encouraged to stay connected through platforms like Telegram, Discord, and Twitter, where they can receive updates, engage with other community members, and participate in discussions related to vault management and investment opportunities. This helps users remain informed about platform developments, ensuring they always have access to the latest industry trends and insights.",
        "Ultimately, Univault serves as a comprehensive platform that simplifies the complexities of vault management and liquidity provision. Its user-centric design, coupled with powerful tools for managing assets, ensures that both depositors and vault managers can maximize their potential returns while maintaining full control over their investments. Whether you're new to investing or an experienced professional, Univault provides everything you need to manage, monitor, and grow your assets in a secure and efficient manner."
      ],
    },
    {
      id: 15,
      image1: [image15, whatismyip1, whatismyip2, whatismyip3, whatismyip4],
      img: image15,
      title: "Whatismyip",
      category: ["React"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "In an increasingly digital world, understanding your online presence is more crucial than ever. Whatismyip offers a suite of essential detection tools designed to assist both developers and general users in navigating the complexities of internet usage. Our platform provides tools for IP Location Lookup, DNS Leak Test, NS Lookup, and DNS Lookup, all aimed at giving users valuable insights into their IP addresses, domains, and associated websites. By leveraging these tools, users can make informed decisions about their online activities, enhancing their security and privacy.",
        "One of the key features of What is My IP is the IP Location Lookup tool. This feature allows users to identify the geographical location tied to a specific IP address, which can be instrumental for developers analyzing traffic patterns or for anyone concerned about where their data is coming from. Understanding the origin of web traffic not only helps in improving website performance but also aids in recognizing potential security threats.",
        "Additionally, our DNS Leak Test tool is essential for users concerned about their privacy. This tool checks if DNS queries are leaking outside a VPN, ensuring that users’ online activities remain confidential. In today’s world, where data breaches and privacy violations are rampant, having the ability to verify your connection’s security is invaluable. Users can take proactive steps to safeguard their information, reinforcing their online privacy.",
        "The NS Lookup and DNS Lookup features further enhance our offering, providing users with the ability to perform detailed analyses of domain names and their associated servers. These tools are especially useful for developers and IT professionals troubleshooting issues or optimizing domain configurations. By offering insights into the underlying infrastructure of websites, What is My IP empowers users to better understand how the internet works and make necessary adjustments to enhance their digital strategies.",
        "At Whatismyip, we are committed to ethical use of our tools. We emphasize that our services are designed for legitimate purposes only; any attempt to misuse these resources for harmful activities is strictly prohibited. This focus on ethical practices ensures that our platform contributes positively to the cybersecurity landscape, fostering a safer online environment for everyone.",
        "Developed and maintained by Al Spera, What is My IP is a free service that continually evolves to meet the needs of its users. By integrating valuable data from Criminal IP, a cyber threat intelligence search engine, we provide even deeper insights into potential threats and vulnerabilities. This partnership enhances our ability to deliver meaningful information, allowing users to better protect themselves against cyber risks.",
        "Whatismyip serves as an invaluable resource for anyone looking to gain a deeper understanding of their online footprint. Whether you are a developer seeking to enhance the security of your applications or a general user aiming to protect your privacy, our platform provides the essential tools needed to navigate the digital landscape safely and effectively. By equipping users with powerful detection tools and promoting ethical practices, we empower individuals to take control of their online security and make informed decisions in an ever-evolving digital world."
      ],
    },
    {
      id: 33,
      image1: [shipmate, shipmate1, shipmate2, shipmate3],
      img: shipmate,
      title: "Shipmate",
      category: ["UI/UX"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
        "In today's fast-paced global economy, efficient logistics and freight management are critical for businesses aiming to thrive and expand. Shipmate emerges as a transformative platform, offering a seamless solution for comparing, booking, and managing freight across the world’s leading logistics providers. With Shipmate, users gain access to a comprehensive suite of services that not only simplifies the shipping process but also opens new avenues for growth and operational efficiency.",
        "Shipmate is designed with user convenience in mind, enabling businesses to effortlessly compare various freight options. The platform integrates services from top logistics providers, allowing users to select the best fit for their shipping needs based on cost, speed, and service quality. This feature empowers businesses to make informed decisions, ensuring they secure the best possible rates while maintaining high service standards. Once a choice is made, users can book shipments directly through the platform, streamlining the entire process from selection to dispatch.",
        "The core of Shipmate's offering lies in its robust freight services. By leveraging a vast network of logistics partners, the platform helps businesses expand their reach into new markets and territories. Whether entering unfamiliar continents or scaling existing operations, Shipmate provides the necessary tools and support to facilitate door-to-door shipping solutions. This capability not only enhances logistics efficiency but also contributes to significant cost savings, making it easier for businesses to manage their supply chains effectively.",
        "In addition to freight services, Shipmate understands the diverse needs of modern businesses. To support growth initiatives, the platform offers a range of business services, including cargo insurance, online payment options, and a paperless workflow system. These services are designed to reduce administrative burdens and enhance operational agility. Cargo insurance ensures peace of mind for businesses, protecting their investments during transit. Meanwhile, the online payment feature simplifies transactions, allowing for swift and secure financial exchanges. The emphasis on a paperless workflow further streamlines operations, reducing the need for physical documentation and enhancing sustainability efforts.",
        "With an array of powerful solutions tailored to meet diverse transportation needs, Shipmate stands out in the logistics sector. The platform is equipped to handle various shipping scenarios, providing agile solutions that adapt to the evolving demands of businesses. Whether it’s time-sensitive deliveries or bulk shipments, Shipmate ensures that logistics challenges are met with effective strategies. The user-friendly interface facilitates easy navigation, allowing businesses to manage their supply chain with confidence and efficiency.",
        "Understanding that logistics challenges can arise at any time, Shipmate prioritizes customer support. The platform offers 24/7 assistance from a team of experts located around the globe. This dedicated support ensures that users receive help at every stage of the shipping process, from booking to delivery. The availability of around-the-clock assistance exemplifies Shipmate's commitment to customer satisfaction, providing reassurance that expert guidance is just a click away.",
        "Shipmate represents a significant advancement in freight management solutions, merging technology with logistics expertise. By providing a centralized platform for comparing, booking, and managing freight, along with comprehensive business services and unwavering support, Shipmate empowers businesses to navigate the complexities of global shipping. Whether looking to expand into new markets or streamline existing operations, Shipmate is the partner that helps turn logistics challenges into opportunities for growth and success."
      ],
    },
    {
      id: 27,
      image1: [dsky2, dsky3, dsky4],
      img: dsky2,
      title: "D-sky",
      category: ["UI/UX"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
        "D-Sky is an innovative UI/UX design project aimed at streamlining the management processes for businesses, specifically in the realm of retail and cash collection. This comprehensive platform includes various modules such as Dashboard, Retailers, Cash Collector, Request Assignment, Pin Management, Notification, Report, Master, Commission Booster, App Management, Website Management, and User Feedback. Each component is meticulously designed to enhance user experience, improve operational efficiency, and foster better communication between users and system administrators.",
        "The Dashboard serves as the central hub for users, providing a clear and concise overview of key performance indicators (KPIs) and real-time metrics. Users can easily access vital statistics related to sales, cash collections, and performance trends through visually appealing graphs and charts. This module aims to empower decision-makers by delivering insights at a glance, thus enabling them to make informed decisions swiftly.",
        "The Retailers module facilitates seamless management of retailer information. Users can easily add, edit, and monitor retailer profiles, including contact details, sales performance, and commission structures. This functionality ensures that businesses maintain strong relationships with their retail partners, driving higher sales and enhancing collaboration.",
        "D-Sky's Cash Collector feature is tailored for cash collection representatives. It allows them to manage and track cash collection activities effectively. With functionalities like real-time collection status updates and detailed collection reports, this module helps representatives stay organized and informed. The intuitive design ensures that users can quickly navigate through their tasks, ultimately improving productivity.",
        "The Request Assignment module enhances the efficiency of task delegation within the platform. Users can assign specific tasks or requests to team members, monitor their progress, and ensure timely completion. This feature is designed to improve accountability and streamline operations by providing a clear structure for task management.",
        "The Pin Management feature is crucial for businesses that utilize unique electronic pins for transactions. D-Sky offers a robust system for generating, managing, and distributing pins securely. This module is designed to prevent misuse and ensure that users can easily access and manage their pin-related operations.",
        "Effective communication is vital for any organization, and the Notification module plays a key role in keeping users informed. This feature sends alerts and notifications about important updates, deadlines, and reminders, ensuring that users are always up-to-date with relevant information.",
        "The Report module allows users to generate comprehensive reports based on various metrics and performance indicators. These reports can be customized according to user preferences, providing insights into sales trends, cash collection performance, and more. By leveraging data-driven insights, users can strategize effectively and identify areas for improvement.",
        "The Master module serves as a foundational component for managing core data elements within D-Sky. Users can maintain a centralized repository of essential information, including user accounts, roles, permissions, and system configurations. This module ensures that the platform remains organized and scalable as the business grows.",
        "The Commission Booster feature incentivizes retail partners by providing flexible commission structures. Users can configure commission rates based on performance, encouraging retailers to achieve sales targets. This module fosters a competitive environment, ultimately driving sales growth.",
        "D-Sky offers robust App Management and Website Management modules, allowing users to oversee their mobile and web applications effectively. Users can manage content, monitor performance metrics, and implement updates seamlessly. This ensures a consistent brand experience across all digital touchpoints.",
        "User feedback is essential for continuous improvement. The User Feedback module allows users to submit suggestions, report issues, and share their experiences. This feedback loop helps the D-Sky team understand user needs better and make data-driven decisions for future enhancements.",
        "D-Sky is designed to empower businesses with a comprehensive management solution. Its intuitive UI/UX, combined with powerful features, ensures that users can navigate the platform with ease while maximizing operational efficiency. By integrating various modules into one cohesive system, D-Sky stands out as a transformative tool for retail and cash collection management, ultimately driving growth and success."
      ],
    },
    {
      id: 28,
      image1: [
        certificate1,
        certificate2,
        certificate3,
        certificate4,
        certificate5,
        certificate6,
      ],
      img: certificate1,
      title: "Certificate",
      category: ["UI/UX"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
        "In today’s digital age, the demand for visually appealing and professionally designed certificates has become increasingly important across various sectors, including education, corporate training, and personal achievements. This project presents a comprehensive UI/UX design for a certificate application that caters to multiple categories, providing users with the tools to create customized certificates effortlessly. The application offers a diverse range of design templates, focusing on categories such as common, educational, funny, and landscape certificates. This variety ensures that users can find the perfect design for any occasion.",
        "The primary goal of this project is to develop an intuitive and user-friendly application that allows users to create and customize certificates tailored to their specific needs. It aims to provide a wide variety of certificate templates categorized by themes while ensuring an easy-to-navigate interface that enhances user experience. Additionally, the application will allow users to personalize certificates with text, images, and other design elements. Supporting multiple file formats for downloading and sharing certificates is also a key aspect of the project.",
        "The application targets a diverse audience, including educators, corporate trainers, event organizers, and individuals looking to create certificates for personal achievements or events. Each user will benefit from the application’s ability to meet unique design needs while maintaining professional quality. By addressing the requirements of various sectors, the application aims to become a go-to resource for certificate creation.",
        "One of the standout features of the application will be its certificate categories. These will include common templates for general use, suitable for various occasions such as awards and certifications. There will also be educational designs aimed at educators and students for academic achievements and course completions. For more informal settings, funny templates will be available, perfect for parties or light-hearted awards. Finally, aesthetic landscape templates will cater to creative fields, offering visually striking presentations.",
        "The user interface will prioritize simplicity and accessibility, allowing users to navigate effortlessly through the app. Intuitive menus and clear icons will guide users in selecting templates, customizing designs, and saving their creations. This streamlined approach aims to minimize barriers to entry for users who may not have design experience.",
        "Customization will be a key aspect of the user experience. Users will have the ability to personalize their certificates with text fields for names, dates, and descriptions. They can upload images for logos or personal photos, choose color palettes to match branding or personal preferences, and select fonts to enhance readability and aesthetic appeal. These options will empower users to create truly unique certificates that reflect their individuality.",
        "Furthermore, the application will support multiple file formats, including PDF, JPEG, and PNG, enabling users to download their certificates for printing or digital sharing. Integration with social media platforms will facilitate easy sharing of achievements. A real-time preview feature will also allow users to see their certificate as they make edits, ensuring that they can refine their designs until they achieve the desired result.",
        "The application will be developed using modern technologies to ensure a responsive and smooth user experience. The front end will be built with HTML, CSS, and JavaScript, leveraging frameworks like React or Angular for dynamic user interactions. The back end may utilize Node.js for server management, along with a cloud storage solution for image uploads and template management. Ultimately, this UI/UX design certificate application aims to revolutionize how individuals and organizations create and distribute certificates. By providing a platform with diverse templates, user-friendly navigation, and robust customization options, the application will cater to the evolving needs of its users, enhancing the aesthetic appeal of certificates while simplifying the process of recognition and achievement across various fields."
      ],
    },
    {
      id: 29,
      image1: [
        newreassiace1,
        newreassiace2,
        newreassiace3,
        newreassiace4,
        newreassiace5,
      ],
      img: newreassiace1,
      title: "Certificate",
      category: ["UI/UX"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
        "New Renaissance Technology Services (NRTS) is a dynamic force in the Information and Communication Technology (ICT) sector, with over a decade of experience delivering innovative solutions. The company has established itself as a leading regional service provider, and its new project, “Newreassiace,” aims to further enhance its reputation by offering superior technology and smart solutions through an improved online interface. This project focuses on creating a seamless, user-friendly experience for clients, showcasing a comprehensive array of products and services designed to meet the demands of modern businesses and smart cities.",
        "The core mission of NRTS is to empower businesses and communities with cutting-edge technological solutions. The company envisions itself as a leader in the ICT industry by continually evolving and providing services that adapt to the latest innovations. With a focus on reliability, quality, and customer satisfaction, NRTS strives to be the go-to provider for smart city solutions, workforce services, and allied technologies that address a wide range of business challenges.",
        "The Newreassiace platform will showcase a variety of advanced products aimed at enhancing urban infrastructure and operational efficiency. One of the key offerings is Smart City Solutions, which leverage AI-based technologies to optimize resources, improve public safety, and drive sustainable development. This includes smart traffic management systems, intelligent waste management, and advanced surveillance tools that are crucial for modern urban environments. Additionally, the In-Vehicle Monitoring Systems allow real-time tracking and management of fleet vehicles, ensuring safety and cost-effectiveness for businesses. These systems provide critical data on vehicle performance and driver behavior, allowing companies to streamline their operations.",
        "NRTS also offers Automobile Speed Governor Solutions, which play a vital role in improving road safety by regulating vehicle speeds. Another important offering is the M2M SatComm Solutions, which enable seamless machine-to-machine communication through IoT technology, helping businesses achieve higher levels of automation and efficiency. Moreover, NRTS provides Web-based AI Analytics Tools that allow organizations to harness data-driven insights to enhance decision-making processes and overall performance.",
        "In addition to its diverse product line, NRTS offers a range of specialized services. Workforce Services include IT talent augmentation, ensuring that businesses can access the skills they need to thrive in today’s digital landscape. The company also provides Hire-Train-Deploy services, which help businesses quickly onboard and train new talent, aligning workforce capabilities with organizational goals. Allied Solutions complement these offerings, providing additional support to ensure that clients have all the resources necessary to achieve their technology-driven objectives.",
        "At its core, New Renaissance Technology Services prides itself on being a trusted partner for its clients, not just a service provider. The company’s experienced and resourceful team is dedicated to delivering high-quality solutions that meet the unique needs of each client. Over the years, NRTS has carved a niche for itself in the competitive world of ICT by staying ahead of industry trends and consistently delivering results.",
        "Through the Newreassiace project, NRTS aims to provide a digital platform that effectively communicates its value propositions, making it easier for clients to explore and engage with its offerings. The enhanced user experience will help the company maintain its position as a leader in the smart technology sector, while also broadening its reach to new markets. In conclusion, Newreassiace is not just about improving the company’s online presence but about reinforcing NRTS’s commitment to delivering superior technology solutions that drive progress and innovation in today’s rapidly evolving world."
      ],
    },
    {
      id: 30,
      image1: [vendor1, vendor2, vendor3, vendor4, vendor5],
      img: vendor1,
      title: "Vendor",
      category: ["UI/UX"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
        "The 'Vendor' project is a comprehensive UI/UX design concept for an online textile market. The core vision revolves around showcasing personal collections of textiles, fabrics, and patterns that reflect the designer's unique style and interests. It is not just a marketplace but a curated platform where vendors can display their personal tastes and preferences, offering customers a selection of textiles chosen with care and passion.",
        "The platform enables vendors to easily create, manage, and showcase their products. The design for listing management focuses on simplicity and functionality. Each listing will feature detailed images, fabric descriptions, and relevant product information, making it easier for potential buyers to browse and make informed decisions. The interface is designed to be user-friendly, allowing vendors to quickly upload their products with minimal effort.",
        "A streamlined order management system is integrated into the dashboard, enabling vendors to track orders efficiently. This includes real-time updates on order statuses, payment confirmations, and shipment tracking. The goal is to give vendors complete control over their sales pipeline, from order placement to delivery. Clear notification systems will alert vendors about new orders, pending actions, or customer queries related to their products.",
        "The payment management feature allows vendors to securely manage transactions. It supports multiple payment gateways, ensuring smooth and secure financial transactions. The design prioritizes transparency and ease, giving vendors access to a detailed breakdown of their earnings, pending payments, and other financial insights. Vendors can track payment statuses, view transaction history, and manage refunds or disputes within the same dashboard.",
        "Vendors can customize their profiles to reflect their personal style and brand identity. The profile management feature provides the flexibility to upload images, add a bio, and link social media accounts. This creates a more personalized shopping experience for customers, giving them a better understanding of the vendor behind the products. The design encourages creativity while ensuring a professional and organized display of vendor information.",
        "The platform includes a follower management system, allowing vendors to build a community of loyal customers. Vendors can track who follows them, interact with their followers, and send updates on new collections, promotions, or events. The follower system is designed to increase engagement, encouraging customers to stay connected with their favorite vendors and receive personalized recommendations based on their preferences.",
        "Effective communication between vendors and customers is a priority, and the messaging system provides a seamless way for them to connect. Vendors can receive inquiries about products, handle customer service queries, and negotiate custom orders directly through the platform. The messaging interface is clean and easy to navigate, ensuring that vendors can respond to messages promptly and professionally.",
        "The UI/UX design of 'Vendor' is centered around accessibility, aesthetic appeal, and functionality. The dashboard offers a cohesive, intuitive experience where every function is clearly laid out, ensuring vendors can manage their operations without confusion. The overall look is modern, clean, and elegant, with elements that reflect the personal style and taste of the designer, aligning with the platform's emphasis on individuality and curated choices."
      ],
    },
    {
      id: 31,
      image1: [reseller1, reseller2, reseller3, reseller4, reseller5],
      img: reseller1,
      title: "Reseller",
      category: ["UI/UX"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
        "The 'Reseller' project aims to create an innovative online platform for the new trade cloth market, catering to modern entrepreneurs and retailers. It offers a seamless, intuitive, and user-friendly interface to manage all aspects of the cloth trade business. The dashboard is the heart of the platform, integrating essential features such as listing, order management, payment management, profile management, follower management, and messaging, each designed with the end-user in mind.",
        "The listing feature enables resellers to upload, update, and organize their product catalogs efficiently. Users can easily add descriptions, images, pricing, and other essential details for each product. This feature provides the flexibility to manage inventories, ensuring the stock is up-to-date and visible to potential buyers.",
        "The order management system streamlines the entire purchase process. From receiving customer orders to tracking fulfillment, this feature enables resellers to monitor and manage their sales in real-time. It includes tracking shipment statuses, managing cancellations or returns, and viewing historical order data, allowing users to offer reliable service to their customers.",
        "With integrated payment management, resellers can easily handle transactions. The system supports various payment methods, ensuring smooth financial operations. It provides insights into revenue, completed transactions, pending payments, and detailed invoices, giving users control over their financial activities.",
        "Profile management allows users to create and manage their profiles, personalizing their brand on the platform. This feature includes updating personal details, adding business information, and customizing store appearances, offering a cohesive identity to resellers for better engagement with customers.",
        "In the competitive cloth trade market, engaging with followers is key to business success. The follower management feature helps resellers connect with their audience by tracking and engaging with followers. Users can send updates, promote new listings, and monitor follower growth, fostering long-term relationships with potential customers.",
        "Effective communication is crucial for business operations, and the messaging system built into the dashboard enables direct communication between resellers and their buyers. Whether resolving queries, negotiating deals, or offering customer support, this feature ensures smooth and real-time interaction to enhance customer satisfaction.",
        "The 'Reseller' dashboard is designed to be visually clean, with intuitive navigation for ease of use. Each feature is strategically placed for minimal clicks and maximum efficiency. The user interface is crafted to be responsive, ensuring that resellers can manage their business on any device, from desktops to smartphones. The aesthetic balances a professional and approachable feel, aligning with the modern e-commerce landscape of the cloth trade industry.",
        "By blending functionality with user-centric design, 'Reseller' offers a comprehensive platform to help cloth trade businesses grow and thrive in the digital space."
      ],
    },
    {
      id: 32,
      image1: [
        fracassets2,
        fracassets1,
        fracassets3,
        fracassets4,
        fracassets5,
        fracassets6,
      ],
      img: fracassets2,
      title: "FracaAssets",
      category: ["UI/UX"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "This application helps everyone to arrange their party and invite as many friends as they want. It helps them to register even minor details related to the party. Moreover, the colour combination is also attractive. Additionally, various functionalities are given such as search bar, remember the party, my parties, participate party, capture party presence, rate party and many more.",
        "'FracAssets' focuses on creating a seamless, user-friendly interface that aligns with the platform’s mission of making real estate investment accessible, diversified, and innovative. By prioritizing simplicity and clarity, the design aims to empower users of all experience levels to easily explore, invest, and manage their portfolios. The core goal of the design is to give users full control over their investments, ensuring a confident and rewarding experience.",
        "A central element of the UI will be a comprehensive dashboard that provides users with an overview of their entire portfolio. The dashboard will feature intuitive visualizations such as graphs and charts that highlight key metrics, including asset diversification. Users will be able to see how their investments are spread across various property types, such as residential, commercial, or agricultural assets, helping them track performance at a glance. This clear, data-driven design will allow users to assess risk and returns without needing to dive deep into complex financial details.",
        "FracAssets’ unique real estate crowdfunding option will also be seamlessly integrated into the design. To make this process easy for users, the UI will guide them through the various steps of crowdfunding, from selecting properties to contributing funds. A step-by-step investment process will be laid out with clear call-to-action buttons, such as “Invest Now” and “Track Returns,” helping users navigate without confusion. Furthermore, rental yields will be regularly updated and displayed in an organized, easy-to-read format, giving users insight into their earnings over time.",
        "Accessibility is another cornerstone of the FracAssets platform, and this will be reflected in the design by allowing users to invest smaller amounts of capital. The UI will showcase curated investment opportunities that suit different budgets, making property investment achievable for a wider audience. An investment calculator will be included to help users simulate potential returns based on various investment amounts. This feature will empower users to experiment and plan without feeling overwhelmed by large financial commitments, making real estate investment more approachable for everyone.",
        "In addition to accessibility, trust is a major focus of the design. The platform will offer curated investment opportunities, each of which will include detailed information on the property’s vetting process, expert ratings, and return forecasts. These elements will be clearly visible on each property listing, helping users make informed decisions. Trust signals, such as badges for “Expert Picks” or “Top Performing Properties,” will be incorporated to further reassure users that their investments are secure.",
        "Education will also play a crucial role in the FracAssets UI/UX design. To support novice investors, the platform will include helpful tooltips and micro-tutorials that explain key real estate concepts in simple terms. These educational prompts will be strategically placed throughout the interface to guide users as they explore different investment options, ensuring they feel informed and confident in their choices.",
        "FracAssets’ long-standing expertise in real estate will be highlighted across the platform to build credibility. The platform’s 160+ years of experience will be emphasized through sections dedicated to its legacy, alongside user testimonials and expert content. A “Get Expert Advice” feature will allow users to contact the FracAssets advisory team for personalized guidance, further strengthening trust and engagement.",
        "Overall, the UI/UX design of FracAssets will focus on providing a clean, modern, and intuitive experience for users. By offering features that promote accessibility, transparency, and trust, the design will transform the traditionally complex world of real estate investment into a more inclusive, reliable, and profitable opportunity for all types of investors."
      ],
    },
    {
      id: 17,
      image1: [conciergeclub1, conciergeclub2, conciergeclub3, conciergeclub5],
      img: conciergeclub1,
      title: "CONCIERGECLUB",
      category: ["WordPress"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "CONCIERGECLUB, a premium experiential marketing and event agency designed to elevate brands through unforgettable experiences. This website serves as a dynamic platform that showcases our expertise in creating tailored, immersive events and innovative marketing strategies that resonate with audiences and foster meaningful connections.",
        "At CONCIERGECLUB, we specialize in experiential marketing, offering interactive campaigns that engage consumers on a personal level. Our website highlights various case studies that demonstrate how we create unique experiences, from captivating pop-up events to immersive digital activations. Potential clients can explore our approach to designing memorable interactions that drive brand loyalty.",
        "Our event management services encompass everything from initial planning to flawless execution. The website outlines our meticulous process, showcasing how we handle logistics, creative direction, and vendor coordination. With detailed descriptions of our event planning capabilities, visitors can see how we ensure that every aspect of their event aligns with their brand vision.",
        "The site features a dedicated section for brand activations, illustrating how we develop unique experiences that encourage direct consumer interaction with products and services. Through engaging visuals and examples, potential clients can understand how our activations inspire participation and create lasting impressions.",
        "CONCIERGECLUB prides itself on innovative design. The website highlights our creative strategy services, demonstrating how we collaborate with clients to bring their brand stories to life through stunning visuals and cutting-edge technology. This section emphasizes our commitment to crafting aesthetically pleasing and impactful experiences.",
        "Understanding the importance of influencer marketing, our website includes information about our VIP and influencer engagement strategies. By detailing how we connect brands with key opinion leaders, we show potential clients how we can help amplify their message and reach a broader audience.",
        "The CONCIERGECLUB website outlines our insight-driven planning process, showcasing how we tailor each experience to align with brand identity and audience expectations. With a focus on creative innovation, visitors will see how we continually push the boundaries to create unforgettable experiences that captivate and engage.",
        "Additionally, our commitment to post-event amplification is highlighted, demonstrating how we extend the reach of events through social media and PR efforts. This ensures that our clients’ messages continue to resonate long after the event concludes.",
        "By visiting our website, potential clients will discover that partnering with CONCIERGECLUB means accessing a dedicated team committed to delivering exceptional results through thoughtful design and immersive experiences. Whether it’s a high-profile product launch, an innovative brand activation, or a luxurious VIP event, we transform ideas into reality and ensure projects leave a lasting impact on both the brand and its audience.",
        "Experience the difference with CONCIERGECLUB—where your vision comes to life. Our website is your gateway to discovering how we can help elevate your brand through unique, experiential marketing solutions that truly resonate."
      ],
    },
    {
      id: 18,
      image1: [koski1, koski2, koski3],
      img: koski1,
      title: "KOSKII",
      category: ["WordPress"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
       "KOSKII is designed to offer an exceptional online shopping experience, particularly for customers looking for saris and cholis. Here’s how KOSKII serves its customers and enhances their shopping journey.",
       "KOSKII provides a vast array of saris and cholis, making it a one-stop shop for anyone seeking traditional Indian wear. With options available in various fabrics like cotton, silk, georgette, and chiffon, customers can choose outfits that suit their style and occasion. Whether it's for a wedding, festival, or casual event, shoppers can easily find the perfect attire.",
       "The website features a comprehensive filtering system that allows customers to narrow down their search based on specific preferences. By filtering products by fabric, color, size, karigari (craftsmanship), and occasion, customers can find exactly what they are looking for without sifting through unrelated items. This personalized approach saves time and enhances the overall shopping experience.",
       "KOSKII showcases high-quality images of each product, allowing customers to appreciate the intricate details and vibrant colors of the saris and cholis. Detailed product descriptions provide information about fabric, care instructions, and design features, ensuring customers know what they are purchasing.",
       "The inclusion of customer reviews helps potential buyers make informed decisions. By reading about the experiences of previous customers, shoppers can gauge the quality and fit of the products. This transparency builds trust and confidence in the purchasing process.",
       "The KOSKII website is designed with user experience in mind. A clean and intuitive layout allows customers to navigate effortlessly through categories and collections. The straightforward checkout process makes it easy for customers to complete their purchases quickly and securely.",
       "KOSKII prioritizes customer satisfaction. Should customers have any questions or concerns, responsive customer support is available to assist them. Whether it’s about product details, order status, or returns, the support team is dedicated to ensuring a positive experience.",
       "Shopping at KOSKII means customers can browse and purchase from the comfort of their homes. There’s no need to navigate crowded markets or limited local selections. With just a few clicks, customers can explore a wide range of traditional wear and have it delivered directly to their doorstep.",
       "By shopping at KOSKII, customers are not only purchasing clothing but also embracing and celebrating the rich cultural heritage of Indian fashion. The carefully curated collections reflect traditional craftsmanship while incorporating modern trends, making it easy for customers to wear their heritage proudly.",
       "KOSKII is designed to be a valuable resource for customers seeking beautiful saris and cholis. With its wide selection, personalized shopping options, visual appeal, customer reviews, and user-friendly interface, KOSKII ensures that every shopping experience is enjoyable, efficient, and fulfilling. By choosing KOSKII, customers can confidently embrace the elegance of Indian fashion while enjoying the convenience of modern online shopping."
      ],
    },
    {
      id: 19,
      image1: [clothesmnia1, clothesmnia, clothesmnia2],
      img: clothesmnia1,
      title: "CLOTHESMANIA",
      category: ["WordPress"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "CLOTHESMANIA is an innovative e-commerce platform dedicated to providing a diverse selection of children's clothing and essential products. Our mission is to create a convenient and enjoyable shopping experience for parents and caregivers, ensuring they can find high-quality, stylish, and affordable items for their little ones. By focusing on user satisfaction, CLOTHESMANIA aims to become a trusted destination for all child-related clothing and accessories.",
        "The primary target audience for CLOTHESMANIA consists of parents, guardians, and family members looking for clothing and essentials for children aged newborn to 12 years. This demographic includes everyone from new parents to seasoned caregivers who prioritize quality, comfort, and style in their children's apparel. Our platform also appeals to gift-givers seeking thoughtful presents for birthdays, holidays, and special occasions, making it a versatile shopping destination.",
        "CLOTHESMANIA boasts an extensive catalog of products tailored to meet the needs of children and their caregivers. Our offerings include a variety of clothing for infants, toddlers, boys, and girls, featuring bodysuits, rompers, dresses, and outfits designed for both comfort and ease of dressing. Additionally, our seasonal collections ensure that children remain comfortable throughout the year with appropriate swimwear, winter coats, and festive outfits. We also provide a range of accessories, such as hats, socks, shoes, and practical items like diaper bags and changing mats.",
        "In addition to clothing and accessories, CLOTHESMANIA offers a curated selection of safe and engaging toys, books, and educational materials that promote learning and development. Everyday essentials, including bedding, blankets, and nursery decor, are also available to help create nurturing environments for children. This comprehensive product range ensures that customers can find everything they need for their children in one convenient location.",
        "The CLOTHESMANIA website is designed with user experience at the forefront, featuring intuitive navigation and clearly defined categories to facilitate browsing. Customers can refine their searches using filters such as size, color, and price range, ensuring they quickly find what they need. Each product is accompanied by high-quality images and detailed descriptions to provide a clear understanding of what is being purchased, while user-generated reviews help build trust in our offerings.",
        "Customer engagement is a significant focus at CLOTHESMANIA. We employ personalized recommendations utilizing data analytics to suggest products based on customers' browsing and purchase histories. Our commitment to customer satisfaction is reflected in regular promotions, loyalty programs, and special discounts for first-time buyers, which encourage repeat business. Additionally, we aim to foster community through social media engagement and a blog that shares parenting tips, style guides, and product highlights.",
        "Efficient logistics and excellent customer support are crucial to our operations. CLOTHESMANIA collaborates with reliable shipping partners to ensure prompt delivery, complemented by a hassle-free return policy. Our customer support team is available through multiple channels, including live chat, email, and phone, to assist with any inquiries or issues, providing a seamless shopping experience from start to finish.",
        "CLOTHESMANIA is committed to creating a delightful shopping experience for parents and caregivers while offering a comprehensive range of children's clothing and products. With a focus on quality, convenience, and customer satisfaction, we aim to establish ourselves as a leading e-commerce platform in the children's apparel market. By continuously adapting to customer needs and trends, CLOTHESMANIA is poised for long-term success and growth."

      ],
    },
    {
      id: 20,
      image1: [Goodera2, Goodera1, Goodera3, Goodera4],
      img: Goodera2,
      title: "Goodera",
      category: ["WordPress"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "Goodera is an innovative platform designed to bridge the gap between corporate volunteers and nonprofit organizations (NPOs) on a global scale. The project's primary mission is to facilitate meaningful connections between companies and social causes, empowering employees to engage in impactful volunteering activities while supporting the growth and effectiveness of nonprofits worldwide.",
        "At its core, Goodera offers a comprehensive ecosystem that caters to the needs of both corporations and NPOs. For businesses, the platform provides access to a diverse array of volunteering opportunities that can be tailored to suit various preferences and schedules. These range from indoor and outdoor activities to remote and on-demand options, ensuring that employees can contribute in ways that best fit their interests and availability. With over 5000 activities hosted across 500+ cities and support in 20+ languages, Goodera makes social engagement accessible and appealing to a broad spectrum of participants.",
        "For nonprofits, Goodera serves as more than just a volunteer matching service. It offers a vibrant community of 50,000+ NPOs, fostering knowledge sharing, networking, and mutual support. The platform assists NPOs in building their brand presence, raising funds through corporate donation plans, and gaining visibility through social media promotion. Additionally, Goodera provides educational resources and webinars to help nonprofits maximize their impact and operational efficiency.",
        "The project's global reach is impressive, spanning over 100 countries and impacting 1 million beneficiaries. Goodera has forged partnerships with more than 400 corporate enterprises, including 60 Fortune 500 companies, effectively making social good accessible to over 10 million employees worldwide. This extensive network allows for a significant scaling of volunteer efforts and social impact.",
        "Goodera's approach to corporate volunteering is multifaceted, addressing a wide range of causes from LGBTQ+ rights and environmental conservation to fighting global hunger. The platform's user-friendly interface and high satisfaction ratings (4.8 out of 5 from 10,000+ ratings) demonstrate its effectiveness in creating engaging and meaningful experiences for volunteers.",
        "By leveraging technology and strategic partnerships, Goodera aims to revolutionize the landscape of corporate social responsibility. It not only facilitates volunteer matching but also cultivates a culture of giving and social awareness within organizations. Through its comprehensive offerings, Goodera is working towards creating a more connected, compassionate, and socially responsible global community, one volunteer at a time."
      ],
    },
    {
      id: 21,
      image1: [blinds2, blinds1, blinds3, blinds4],
      img: blinds1,
      title: "Blinds",
      category: ["WordPress"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "Blinds on Curtains is an innovative online platform dedicated to providing customers with a unique and customizable experience in selecting curtains for their homes and offices. Our mission is to offer a diverse range of high-quality curtains that can be tailored to meet individual preferences, ensuring that every customer finds the perfect fit for their space.",
        "One of the standout features of Blinds on Curtains is our extensive customization options. Customers can choose from a wide array of colors, fabrics, and designs to create curtains that match their personal style and complement their interiors. This flexibility allows customers to express their individuality while ensuring their curtains enhance the overall aesthetic of their rooms.",
        "We offer an expansive palette of colors, ranging from classic neutrals to vibrant hues. This variety allows customers to visualize how different shades will look in their space, helping them make informed decisions that reflect their taste. Our collection includes various fabric options, including sheer, blackout, and patterned materials, catering to different preferences.",
        "Whether customers prefer light and airy curtains that let in natural light or heavier fabrics that provide privacy and insulation, we have something for everyone. Additionally, customers can select from a variety of designs, such as grommet, rod pocket, and pleated styles, each thoughtfully crafted to cater to different tastes and functional needs.",
        "To ensure a seamless shopping experience, our website features a user-friendly interface. Customers can easily navigate through various categories, filter options based on their preferences, and visualize their selections through high-quality images and virtual room settings. Our customization tool allows customers to see their choices in real time, giving them a clear picture of the final product before making a purchase.",
        "At Blinds on Curtains, quality is our top priority. We source our fabrics from reputable suppliers to ensure durability and a luxurious feel. Each curtain is crafted with precision and care, adhering to the highest standards in the industry, so customers can trust that they are receiving a product that not only looks stunning but also stands the test of time.",
        "We believe in providing exceptional customer service. Our dedicated support team is available to assist customers with any questions or concerns they may have throughout their shopping journey. From choosing the right fabric to placing an order, we are here to help every step of the way.",
        "Understanding the importance of sustainability, Blinds on Curtains is also committed to eco-friendly practices. We offer a selection of fabrics made from sustainable materials, and our production processes are designed to minimize waste. By choosing our products, customers can feel good about making environmentally conscious decisions for their homes.",
        "Blinds on Curtains is more than just a shopping destination; it is a platform for creativity and personalization. We empower customers to transform their spaces with custom curtains that reflect their style and needs. With a focus on quality, user experience, and sustainability, we are poised to become a leader in the online curtain market. Join us in creating beautiful, customized window treatments that enhance your living or working environment!"
      ],
    },
    {
      id: 22,
      image1: [diakraff2, diakraff1, diakraff3, diakraff4],
      img: diakraff2,
      title: "Diakraff",
      category: ["WordPress"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "Diakraff is an innovative e-commerce platform dedicated to offering an exquisite range of diamonds and jewelry, designed to cater to both individual tastes and market demands. Our mission is to redefine the online shopping experience for customers seeking high-quality diamonds and unique jewelry pieces. By combining advanced technology with a user-friendly interface, Diakraff ensures a seamless browsing experience that allows customers to explore and purchase from a vast collection of products.",
        "At the heart of Diakraff's offerings is our commitment to quality and authenticity. We partner with reputable suppliers and artisans to source ethically mined diamonds and craft stunning jewelry pieces. Our inventory includes an array of carefully selected diamonds, complemented by a curated selection of jewelry items that reflect current trends while also embracing timeless designs. Each piece is accompanied by detailed descriptions and certifications, providing customers with confidence in their purchases.",
        "To enhance the shopping experience, Diakraff incorporates advanced filtering options that allow customers to refine their search according to personal preferences, including price range, style, and metal type. This level of customization ensures that each visitor can find exactly what they are looking for, whether it's a stunning engagement ring, a meaningful gift, or a statement piece for special occasions. Our platform also features high-resolution images and 360-degree views of each product, enabling customers to appreciate the intricate details and craftsmanship involved in every piece of jewelry.",
        "Customer satisfaction is paramount at Diakraff, and we strive to provide exceptional service at every touchpoint. Our team is dedicated to assisting customers throughout their shopping journey, from initial inquiries to post-purchase support. We offer various communication channels, including live chat, email, and phone support, ensuring that assistance is always readily available. Additionally, we have implemented a secure payment system that guarantees safe transactions, giving customers peace of mind as they shop.",
        "In today's digital age, social media plays a crucial role in connecting with our audience. Diakraff actively engages with customers through platforms like Instagram, Facebook, and Pinterest, sharing visually captivating content that showcases our products and highlights customer stories. These platforms not only serve as marketing tools but also as spaces for community building, allowing customers to share their experiences and connect over a shared appreciation for fine jewelry.",
        "To further enhance our offerings, Diakraff is committed to staying ahead of market trends and consumer preferences. We continuously update our inventory based on customer feedback and emerging styles, ensuring that our collection remains fresh and relevant. Our blog features articles on jewelry care tips, styling advice, and the latest trends, positioning Diakraff as an authoritative voice in the diamond and jewelry industry.",
        "Diakraff is more than just an e-commerce website; it is a destination for those who value quality, craftsmanship, and a personalized shopping experience. With a focus on providing a wide range of exquisite diamonds and jewelry, supported by exceptional customer service and a vibrant online community, Diakraff aims to become a leading name in the diamond and jewelry market, making the process of purchasing and celebrating life's special moments both enjoyable and memorable."
      ],
    },
    {
      id: 24,
      image1: [inkppt2, inkppt1, inkppt3, inkppt4],
      IMG: inkppt2,
      title: "Inkppt",
      category: ["WordPress"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "In today’s fast-paced digital landscape, where visuals speak louder than words, INK PPT stands out as a leading creative design agency dedicated to breathing life into brands through innovative design solutions. Our mission is to transform abstract ideas into captivating visual narratives that resonate with audiences and leave a lasting impact. At INK PPT, we believe that every brand has a unique story to tell, and we are committed to helping our clients communicate that story in a vibrant and engaging manner.",
        "At the core of our services is our marketing design expertise, which empowers brands to make a significant mark in an increasingly crowded marketplace. Our team of talented designers crafts visually striking marketing collateral, including brochures, flyers, posters, and social media graphics that capture attention and drive engagement. We understand that effective marketing requires more than just aesthetic appeal; it requires a strategic approach that aligns with the brand's goals and speaks to its target audience. By combining creativity with market insights, we ensure that our designs not only look good but also contribute to measurable business outcomes.",
        "Branding is another cornerstone of our offerings at INK PPT. We recognize the importance of a distinctive and memorable brand identity in establishing a lasting presence in the market. Our branding services include the development of visually appealing logos, comprehensive brand guidelines, and cohesive collateral that effectively convey the brand's personality and values. We take a strategic approach to branding, ensuring consistency across all touchpoints to create a unified experience for customers. By differentiating our clients from the competition, we help them build a strong connection with their audience and foster brand loyalty.",
        "Collaboration is at the heart of our creative process. We believe that the best results come from working closely with our clients to understand their vision, goals, and challenges. Our team is committed to listening to our clients and translating their ideas into designs that reflect their brand essence. Whether it’s a startup looking to establish a brand presence or an established enterprise seeking to refresh its image, we approach each project with the same dedication and enthusiasm, ensuring that every design is tailored to meet the unique needs of the client.",
        "In addition to our design services, we also provide comprehensive consulting to guide our clients through the branding and marketing process. Our experts offer insights on market trends, audience analysis, and effective communication strategies, helping clients make informed decisions about their brand’s direction. By combining our creative prowess with strategic thinking, we empower our clients to navigate the complexities of the marketplace and achieve their business objectives.",
        "At INK PPT, we are not just about creating beautiful designs; we are about creating meaningful connections between brands and their audiences. Our designs are strategically crafted to evoke emotions, spark conversations, and inspire action. We understand that design is not just a visual element but a powerful tool for communication. Our goal is to help brands articulate their message clearly and effectively through compelling visuals.",
        "As we look to the future, INK PPT remains committed to innovation and excellence in every project we undertake. We continuously explore new design trends, technologies, and methodologies to enhance our services and deliver outstanding results. Our passion for design and dedication to client success drives us to push boundaries and explore new creative possibilities.",
        "INK PPT is more than a creative design agency; we are partners in our clients' journey towards building a strong and memorable brand. With our expertise in marketing design, branding, and strategic consulting, we are equipped to help businesses thrive in an ever-evolving marketplace. Let us collaborate and reinvent your brand’s visual journey together, creating designs that not only stand out but also make a meaningful impact."
      ],
    },
    {
      id: 25,
      image1: [upsqode2, upsqode1, upsqode3, upsqode4],
      img: upsqode2,
      title: "Upsqode",
      category: ["WordPress"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "In an increasingly digital world, the need for robust software solutions has never been more critical. At Upsqode, we understand that software is not merely a tool; it is the backbone of modern businesses that drives competitiveness and agility. Our approach to digital product engineering is deeply rooted in a human-centric philosophy, where the user always takes precedence. We believe that the best products are those that not only empower users but also deliver tangible business value and contribute positively to society. By leveraging our extensive industry expertise and experience, we work diligently to help you create products that align with these principles.",
        "At Upsqode, our mission is to supercharge your business by elevating your online presence through innovative software, web design, and SEO services. We recognize that digitalization is the key to unlocking potential and enhancing operational efficiency in today’s fast-paced market. Our team is dedicated to providing solutions that are not just technologically advanced but are also tailored to meet the unique needs of each client. Whether you are a startup looking to carve your niche or an established enterprise seeking to innovate, our comprehensive suite of services is designed to cater to businesses of all sizes.",
        "Our strength lies in our ability to tackle complex software engineering challenges head-on. We have a proven track record of success, having collaborated with a diverse range of clients, from fledgling startups to Fortune 500 giants. This breadth of experience allows us to understand the specific requirements and nuances of various industries, enabling us to deliver custom solutions that drive results. Our agile teams of remote engineers are not only skilled but also passionate about building great software. They are committed to a collaborative process, working closely with clients to ensure that the final product exceeds expectations.",
        "The journey to successful software development begins with understanding your unique business challenges. At Upsqode, we take the time to listen to your needs and goals, ensuring that our solutions are aligned with your vision. We employ cutting-edge technologies and best practices to craft software that is not only functional but also scalable, ensuring that your business can grow without limitations. Our expertise extends across various domains, enabling us to provide innovative solutions that are both practical and forward-thinking.",
        "In addition to software engineering, Upsqode excels in web design and SEO services. We believe that a strong online presence is crucial for business success in the digital age. Our design team focuses on creating intuitive, user-friendly interfaces that enhance user experience while effectively communicating your brand message. Coupled with our SEO strategies, we ensure that your website not only attracts visitors but also converts them into loyal customers. We understand that search engine visibility is a vital component of any successful online strategy, and we are here to help you achieve that.",
        "As we continue to navigate the digital landscape, Upsqode remains steadfast in our commitment to innovation and excellence. We are more than just a service provider; we are your partner in achieving digital transformation. With a focus on delivering measurable results, we aim to empower your business to thrive in an ever-evolving market. Together, let’s embark on a journey toward unlocking your business’s full potential through the power of technology. At Upsqode, we are ready to elevate your online presence and redefine what’s possible for your business."
      ],
    },
    {
      id: 26,
      image1: [Fearless1, Fearless2, Fearless3, Fearless4],
      img: Fearless1,
      title: "Fearless",
      category: ["WordPress"],
      excerpt:
        "this project is easy to understand project structure and scalable , fast Loaded.",
      body: [
        // "A variety of custom light-weight UI components such as datepicker, modal, various form elements etc.",
        "Fearless Talent Agency website—a fully developed platform designed to empower talent and maximize their potential in the digital landscape. This website is not just a business; it's a thriving community focused on growing and monetizing audiences.",
        "The Fearless Talent Agency website comes with an established brand identity that resonates with a diverse audience. You’ll inherit a reputation for inclusiveness and empowerment, allowing you to hit the ground running in the talent representation industry.",
        "The website is fully operational and user-friendly, featuring intuitive navigation and a modern design. It includes essential functionalities such as talent profiles, content management, and engagement tools, enabling you to manage your clients and services seamlessly from day one.",
        "With a diverse range of represented talents across beauty, fashion, lifestyle, and more, the website provides a solid foundation for attracting new clients. You will have immediate access to an existing client base, reducing the time and effort needed to build relationships from scratch.",
        "Fearless Talent Agency has been designed to monetize talent effectively. The platform includes integrated tools for negotiating brand partnerships and sponsorships, enabling you to capitalize on various income streams right away. This potential for revenue generation makes the site a valuable investment.",
        "The website is built around a community-centric approach that fosters collaboration and mutual growth. As the new owner, you can continue to cultivate this environment, enhancing client satisfaction and loyalty, which in turn contributes to long-term success.",
        "The website provides a strong foundation for future growth. You can expand the range of services offered, implement new marketing strategies, and explore additional partnerships. With Fearless, you have the tools and community support to innovate and scale the business further.",
        "The website comes with access to valuable resources, including content creation guides, marketing strategies, and industry connections. These resources will aid you in maximizing your investment and achieving your business goals more effectively.",
        "Acquiring the Fearless Talent Agency website is a unique opportunity to step into a flourishing industry with an established platform ready for growth. This website not only provides immediate access to a loyal audience and operational functionalities but also presents numerous avenues for revenue generation and expansion.",
        "Take advantage of this chance to own a dynamic talent agency that empowers individuals to become fearless in their pursuits. Your investment in Fearless Talent Agency will not only yield financial returns but also contribute to a community dedicated to personal and professional growth. Embrace the potential, and let this platform be the foundation of your success in the talent representation industry."
      ],
    },
  ];