import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectOne from '../component/project/ProjectOne';
import banner from "../assets/image/banner-thumb-1.png"
import seoData from "../seoData/Project.json"

const ProjectGridThree = () => {

    return (
        <>
        <SEO {...seoData} />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
                title="Our Projects"
                paragraph ="We have a diverse portfolio because of the industry-vide services and exposure. We’ve served a few multi-nationals too. Refer to our portfolio below."
                styleClass=""
                mainThumb={banner}
            />
            <ProjectOne colSize="col-xl-3 col-lg-4 col-md-6" itemShow="12" portfolio1103 ={"portfolio1103"} columnGap="row-15" parentClass="project-column-4"/>
            <CtaLayoutOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ProjectGridThree;