import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

import greview from "../assets/image/Google_review.svg";
import gdreview from "../assets/image/Glassdoor_review.svg";
import creview from "../assets/image/Clutch_review.svg";
import seoData from "../seoData/WebDevelopment.json"
// import argumentTeam from "../assets/image/services/argumentTeam.svg"
// import liIcon from "../li-icon.svg"

const WebDevelopment = () => {
  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper StaffAugmentation">
        <HeaderOne />
        <BreadCrumbOne title="Web Development" page="Web Development" />

        <div className="section section-padding pb-0">
          <div className="container">
            {/* <div className="row">
              <p className="mb-3">
                Looking for the best mobile app development company that can
                help turn your business ideas Into successful Reality?
              </p>
              <p className="mb-3">
                For full-stack app development, hire our experienced mobile app
                developers. This will enhance your brand visibility and sales.
                We have designed and developed over 1200 Android and iOS-based
                mobile applications from scratch. Want to get your free quote?
              </p>
            </div> */}
            <section id="augmentation-card">
              <div className="py-4">
                <div className="section-header">
                  <h5 className="mb-3">Web Application Development Company</h5>
                  <p>
                    As a leading web app development company, we leverage
                    long-standing experience in building and offering custom web
                    application development services to increase customer
                    satisfaction. We help you implement the right technologies
                    in areas that are best suited to bring your business value.
                  </p>
                  <p>
                    Our focus is to understand “What does the client want?” From
                    ideation to testing and delivery, our developers develop and
                    offer exceptional web app development services for
                    sustainable business growth.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-11 col-lg-9 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      1. Website Development
                    </div>
                    <p className="mb-0 mt-3">
                      Building a complete, functional, and thorough website from
                      scratch is our strength. Our websites are tailored to suit
                      the needs of our customers. We ensure a user-friendly,
                      glitch-free website.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      2. E-Commerce Web App Development
                    </div>
                    <p className="mb-0 mt-3">
                      Our robust eCommerce web app development solution creates
                      a great customer experience and allows you to take
                      advantage of web-based marketing channels to help your
                      business grow.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      3. Software Development
                    </div>
                    <p className="mb-0 mt-3">
                      At ITCODE Infotech, we develop both mobile and web apps.
                      Our software development services are known for
                      scalability and customizations.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      4. Web Application Testing
                    </div>
                    <p className="mb-0 mt-3">
                      We offer unmatched and performance-oriented web
                      application testing services. With the option of both
                      automated and manual testing, the quality, usability, and
                      security of your web application is at the highest level
                      possible.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      5. Web Application Support & Maintenance
                    </div>
                    <p className="mb-0 mt-3">
                      We know building an app alone is not all. It is equally
                      important to timely update and maintain it for seamless
                      user experience. We can provide complete web app
                      development support and maintenance to keep your app
                      glitch-free and updated with latest versions and features.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      6. Progressive Web Apps
                    </div>
                    <p className="mb-0 mt-3">
                      PWAs are reliable, fast and engaging, making it a new
                      standard to increase a client's revenue. Moreover, there
                      are many proven case studies of PWAs like Pinterest,
                      Tinder, Bookmyshow, Trivago and more.
                    </p>
                  </div>
                </div>
              </div>
              <div className="rate-row">
                <div
                  className="row align-items-center text-center aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <div className="col-12 col-lg-12">
                    <div className="review-h4">
                      <h4>Read our reviews on:</h4>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="review">
                      <img
                        src={greview}
                        className=" ls-is-cached lazyloaded"
                        alt="review"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="review review-border">
                      <img
                        src={gdreview}
                        className=" ls-is-cached lazyloaded"
                        alt="review"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="review">
                      <img
                        src={creview}
                        className=" ls-is-cached lazyloaded"
                        alt="review"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default WebDevelopment;
