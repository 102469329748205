import React from 'react'
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';


const SEO = (props) => {
    const {metaTitle,ogDescription,metaDescription,metaRobots,canonicalTag,ogTitle,ogType,ogImage,ogUrl} = props
    return (
        <Helmet>
             <title>{metaTitle || "ITCODE InfoTech"}</title>
          {metaDescription ? (
            <meta name="description" content={metaDescription} />
          ) : (
            ""
          )}
          {metaRobots ? (
            <meta name="robots" content={metaRobots} />
          ) : (
            ""
          )}
          {canonicalTag ? (
            <link rel="canonical" href={canonicalTag} />
          ) : (
            ""
          )}
          {ogTitle ? (
            <meta property="og:title" content={ogTitle} />
          ) : (
            ""
          )}
          {ogType ? (
            <meta property="og:type" content={ogType} />
          ) : (
            ""
          )}
          {ogImage ? (
            <meta property="og:image" content={ogImage} />
          ) : (
            ""
          )}
          {ogUrl ? (
            <meta property="og:url" content={ogUrl} />
          ) : (
            ""
          )}
          {ogDescription ? (
            <meta
              property="og:description"
              content={ogDescription}
            />
          ) : (
            ""
          )}
            {/* <meta charSet="utf-8" />
            <title>{title} | ITCODE Infotech</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="About Us - ITCODEHELP | All types of website and mobile application Devlopment | Simple Solutions for Complex Connections." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" /> */}
        </Helmet>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;