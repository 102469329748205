import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD4xXwK2v2wUokjHPqZlyEs5GfF45DTJ0c",
  authDomain: "itcodehelp-5089f.firebaseapp.com",
  projectId: "itcodehelp-5089f",
  storageBucket: "itcodehelp-5089f.appspot.com",
  messagingSenderId: "304302496199",
  appId: "1:304302496199:web:5337b50063ba87fdb561e2",
  measurementId: "G-647PX5PW6Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage();
export { db, auth, storage };