import React from "react";
import client1 from "../../assets/image/brand/client1.svg";
import client2 from "../../assets/image/brand/client2.png";
import client3 from "../../assets/image/brand/client3.svg";
import client4 from "../../assets/image/brand/client4.svg";
import client5 from "../../assets/image/brand/client5.svg";
import client6 from "../../assets/image/brand/client6.svg";
import client7 from "../../assets/image/brand/client7.svg";
import client8 from "../../assets/image/brand/client8.svg";

const BrandItem = () => {
  return (
    <>
      <div className="col-lg-3 col-6">
        <div className="brand-grid">
          <img src={client1} alt="Brand" />
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="brand-grid">
          <img src={client2} alt="Brand" />
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="brand-grid">
          <img src={client3} alt="Brand" />
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="brand-grid">
          <img src={client4} alt="Brand" />
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="brand-grid">
          <img src={client5} alt="Brand" />
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="brand-grid">
          <img src={client6} alt="Brand" />
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="brand-grid">
          <img src={client7} alt="Brand" />
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="brand-grid">
          <img src={client8} alt="Brand" />
        </div>
      </div>
    </>
  );
};

export default BrandItem;
