import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { db } from "../../src/firebase"; // Import your existing Firebase setup
import { doc, setDoc } from "firebase/firestore"; // Firestore methods
import { FaTimes } from "react-icons/fa";

const VisitorQuickDemo = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Clear error message for the respective field when the user starts typing
    setFormErrors({ ...formErrors, [name]: "" });

    setFormData({ ...formData, [name]: value });
  };

  // Phone validation function
  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    const errors = {
      name: "",
      phone: "",
    };

    if (!formData.name) {
      errors.name = "Name is required.";
    }

    if (!formData.phone) {
      errors.phone = "Phone number is required.";
    } else if (!validatePhone(formData.phone)) {
      errors.phone = "Phone number must be 10 digits.";
    }

    if (errors.name || errors.phone) {
      setFormErrors(errors);
      return; // If there are errors, stop the form submission
    }

    try {
      const docRef = doc(db, "userDetails", formData.phone);
      await setDoc(docRef, {
        name: formData.name,
        phone: formData.phone,
      });

      // Reset form fields and error messages after successful submission
      setFormData({
        name: "",
        phone: "",
      });
      setFormErrors({
        name: "",
        phone: "",
      });

      console.log("Document successfully written!");
      window.open("https://visitordemo.itcodeinfotech.com/", "_blank");

      // Optionally close the form if it is still open
      setFormVisible(false);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      console.log(window.scrollY, "scroll");
      setScroll(window.scrollY > 200);
    });
  }, []);

  return (
    <>
      <div className="">
        <div className="container">
          <div className="section-padding text-start mb--50 pt-5">
            <h2 className="title">Quick Demo</h2>
            <div className="container shadow-box">
              <div className="mt-5 content">
                <h5 className="title">
                  Experience Our Visitor Management System: Live Demo
                </h5>
                <p p className="w-100 mb-3">
                  Get a quick overview of how our Visitor Management System
                  works! In this demo, you can experience the key features of
                  the system firsthand:
                </p>
                <ul style={{ lineHeight: "1.5rem" }}>
                  <li>
                    Visitor Check-In: See how easy it is for visitors to check
                    in using QR codes, mobile numbers, or pre-registration.
                  </li>
                  <li>
                    Real-Time Notifications: Witness how hosts are notified
                    instantly when their visitors arrive.
                  </li>
                  <li>
                    Report Generation: Explore how reports are generated for
                    visitor data, pre-registrations, and attendance.
                  </li>
                  <li>
                    Visitor Checkout: Experience the simple process of checking
                    out and updating visitor records.
                  </li>
                </ul>
                <p p className="w-100 mb-3">
                  To start the demo, simply click on the "Start Demo" button
                  below and follow the guided steps to explore the system's
                  features.
                </p>
                <div className="">
                  <Link
                    className="mt-4 btn btn-primary px-4"
                    to="#"
                    // onClick={() =>
                    //   window.open("https://visitordemo.itcodeinfotech.com/", "_blank")
                    // }
                    onClick={() => setFormVisible(true)}
                  >
                    Quick Demo
                  </Link>
                </div>
                {formVisible && (
                  <div className="popup-overlay">
                    <div className="popup-container">
                      <div className="popup-header d-flex justify-content-between">
                        <h3 className="popup-title">Don't Miss the Chance!</h3>
                        <button
                          className="btn-close"
                          onClick={() => setFormVisible(false)}
                        >
                          <FaTimes />
                        </button>
                      </div>
                      <div className="popup-body">
                        <p className="popup-subtitle">
                          Join us and experience the Visitor Management System.
                        </p>
                        <form onSubmit={handleSubmit} className="popup-form">
                          <div className="mb-3">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Name"
                              value={formData.name}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            {formErrors.name && (
                              <div className="error-message text-danger text-start">
                                {formErrors.name}
                              </div>
                            )}
                          </div>
                          <div className="mb-3">
                            <input
                              type="text"
                              name="phone"
                              className="form-control"
                              placeholder="Phone Number"
                              value={formData.phone}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            {formErrors.phone && (
                              <div className="error-message text-danger text-start">
                                {formErrors.phone}
                              </div>
                            )}
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            Book Now
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitorQuickDemo;
