import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { db, storage } from "../../firebase";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormCareer = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    resume: "",
  });
  const [result, showresult] = useState(false);
  const [loading, setLoading] = useState();
  const [obj, setObj] = useState();

  const sendEmail = async (e) => {
    e.preventDefault();
    console.log("Form ref ==>", form.current);
    console.log("Form data ==>", formData);

    const ref = collection(db, "applications");
    await addDoc(ref, {
      ...formData,
    });
    setFormData({
      name: "",
      mobile: "",
      email: "",
      resume: "",
    });
    setObj();
    showresult(true);

    //     emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //       form.current.reset();
    //       showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 10000);

  const uploadResume = (e) => {
    // setObj((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    // setobjEdit((prevState) => ({
    //   ...prevState,
    //   [e.target.name]: e.target.value,
    // }));
    setLoading(true);
    try {
      var storageRef = ref(storage, `images/${e.target.files[0].name}`);
      var uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log("======>>>>>>>>>==============>>>>>>>>>>>", url);
            setFormData({ ...formData, resume: url });
            setObj(e.target.files[0].name);
            if (url) {
              setLoading(false);
            } else {
              setLoading(false);
            }
          });
        }
      );
    } catch (error) {
      console.log("eee", error);
      setLoading(false);
    }
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="contact-name"
          required
          value={formData.name}
          onChange={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="contact-email"
          required
          value={formData.email}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
        />
      </div>
      <div className="form-group">
        <label>Contact No.</label>
        <input
          type="tel"
          className="form-control"
          name="contact-phone"
          required
          value={formData.mobile}
          onChange={(e) => {
            setFormData({ ...formData, mobile: e.target.value });
          }}
        />
      </div>
      <div className="form-group mb--40">
        <label>Drop your CV</label>
        <div className="fileUpload">
          <input
            type="file"
            className="form-control fileUpload-inp"
            name="contact-file"
            required
            onChange={(e) => uploadResume(e)}
          />
          <div className="fileUpload-mes">
            Drag & Drop Files Here
            <span>&nbsp;Browse Files</span>
          </div>
        </div>
        {obj && <p>{obj} file uploaded</p>}
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
          disabled={loading}
        >
          {loading ? "Please wait" : "Submit"}
        </button>
      </div>
      {console.log("lllllloading", loading)}

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormCareer;
