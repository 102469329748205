import React from "react";

const VisitorPlan = () => {
  return (
    <>
      <div className="">
        <div className="container">
          <div className="section-padding text-start mb--50 pt-5">
            <h2 className="title">Our Plans</h2>
            <div className="container mt--100 shadow-box">
              <div className="row">
                <div className="col-md-6 shadow p-0">
                  <div className="py-4 text-center">
                    <h3>1 Month Trial</h3>
                    <hr />
                  </div>
                  <div className="text-center">
                    <h4 style={{ color: "#f99f11" }}>Free</h4>
                    <p
                      className="mt-5"
                      style={{ color: "#a8acb6", fontWeight: "600" }}
                    >
                      Access to Basic Plan features
                    </p>
                    <p
                      className="mt--100"
                      style={{ color: "#a8acb6", fontWeight: "600" }}
                    >
                      1 month free trial
                    </p>
                    <button
                      className="my-4 rounded-pill text-white"
                      style={{
                        padding: "15px 35px",
                        border: "none",
                        backgroundImage:
                          "linear-gradient(to right, #f69710 , #e25f0e)",
                        fontWeight: "500",
                      }}
                    >
                      Register Now
                    </button>
                  </div>
                </div>
                <div className="col-md-6 shadow p-0 mt-5 mt-md-0">
                  <div className="py-4 text-center">
                    <h3>Advance</h3>
                    <hr />
                  </div>
                  <div className="text-center">
                    <h4 style={{ color: "#f99f11" }}>Rs.29,500/year</h4>
                    <p
                      className="mt-5"
                      style={{ color: "#a8acb6", fontWeight: "600" }}
                    >
                      All features and add-ons
                    </p>
                    <p
                      className="mt--100"
                      style={{ color: "#a8acb6", fontWeight: "600" }}
                    >
                      Save on annual subscription
                    </p>
                    <button
                      className="my-4 rounded-pill text-white"
                      style={{
                        padding: "15px 35px",
                        border: "none",
                        backgroundImage:
                          "linear-gradient(to right, #f69710 , #e25f0e)",
                        fontWeight: "500",
                      }}
                    >
                      Register Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitorPlan;
