import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BannerOne from "../component/banner/BannerOne";
import BrandOne from "../component/brand/BrandOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import ServicePropTwo from "../component/service/ServicePropTwo";
import TeamOne from "../component/team/TeamOne";
import TestimonialPropTwo from "../component/testimonial/TestimonialPropTwo";
import TestimonialData from "../data/testimonial/TestimonialData.json";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import { slugify } from "../utils";
import seoData from "../seoData/Home.json"
const allData = TestimonialData;

const DigitalAgency = () => {
  const facebookData = allData.filter(
    (data) => slugify(data.fromtext) === "facebook"
  );

  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerOne />
        <div className="section section-padding">
          <div className="container">
            <SectionTitle
              subtitle=""
              title="How do we work?"
              description="Client satisfaction and quality work are our core objectives. We wish to provide the best to our clients."
              textAlignment="heading-left mb--20 mb_md--70"
              textColor=""
            />
            <div className="row">
              <ServicePropTwo />
            </div>
          </div>
          <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                alt="circle"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Line"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Line"
              />
            </li>
          </ul>
        </div>
        <CounterUpOne />
        <div className="section section-padding-2 bg-color-dark">
          <div className="container">
            <SectionTitle
              subtitle="Our Services"
              title="Services we can help you with"
              description="Client satisfaction and quality work are our core objectives. We wish to provide the best to our clients."
              textAlignment="heading-light-left"
              textColor=""
            />
            <div className="row">
              <ServicePropOne
                colSize="col-xl-4 col-md-6"
                serviceStyle=""
                itemShow="6"
              />
            </div>
          </div>
          <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-9.png"}
                alt="Circle"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"}
                alt="Circle"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <ProjectOne
          colSize="col-xl-3 col-lg-4 col-md-6"
          itemShow="12"
          columnGap="row-15"
          parentClass="project-column-4"
        />
        <TeamOne />
        {/* <BlogOne /> */}
        <div className="section section-padding customer-review-area  overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <SectionTitle
                  subtitle=""
                  title="Testimonials"
                  description="So far we have gained immense trust and support from our clients. Have a look at what they are saying about us and our services."
                  textAlignment="heading-left"
                  textColor=""
                />
              </div>
              <div className="col-lg-4">
                <div className="review-site-logo">
                  <a href="https://www.facebook.com/">
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon/fb.png"}
                      alt="Facebook"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <TestimonialPropTwo
                colSize="col-lg-3"
                itemShow="4"
                layoutStyle="testimonial-dark"
                testimonialData={facebookData}
              />
            </div>
          </div>
          <ul className="shape-group-11 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-6.png"}
                alt="line"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="line"
              />
            </li>
          </ul>
        </div>
        <BrandOne />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default DigitalAgency;
