import React from "react";
// import { Link } from "react-router-dom";


const ServicePropTwo = () => {

  return (
    <>
      <div className="col-lg-6">
        <div className={`services-grid service-style-2`}>
          <div className="thumbnail">
            <img
              src={process.env.PUBLIC_URL + "/images/icon/icon-2.png"}
              alt="icon"
            />
          </div>
          <div className="content">
            <h5 className="title">Strategise</h5>
            <ul>
              <li>Listen and understand your idea</li>
              <li>Analyze your requirements</li>
              <li>Research</li>
            </ul>
            {/* <Link
              to={process.env.PUBLIC_URL + `/service`}
              className="more-btn"
            >
              Find out more
            </Link> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className={`services-grid service-style-2`}>
          <div className="thumbnail">
            <img
              src={process.env.PUBLIC_URL + "/images/icon/icon-3.png"}
              alt="icon"
            />
          </div>
          <div className="content">
            <h5 className="title">Creation</h5>
            <ul>
              <li>Create a visualization and product workflow</li>
              <li>Designing the product</li>
              <li>Generating wireframes</li>
            </ul>
            {/* <Link
              to={process.env.PUBLIC_URL + `/service`}
              className="more-btn"
            >
              Find out more
            </Link> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className={`services-grid service-style-2`}>
          <div className="thumbnail">
            <img
              src={process.env.PUBLIC_URL + "/images/icon/icon-1.png"}
              alt="icon"
            />
          </div>
          <div className="content">
            <h5 className="title">Development</h5>
            <ul>
              <li>Begin development with a prototype</li>
              <li>Real-time development</li>
              <li>Testing and Client reviews</li>
            </ul>
            {/* <Link
              to={process.env.PUBLIC_URL + `/service`}
              className="more-btn"
            >
              Find out more
            </Link> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className={`services-grid service-style-2`}>
          <div className="thumbnail">
            <img
              src={process.env.PUBLIC_URL + "/images/icon/icon-4.png"}
              alt="icon"
            />
          </div>
          <div className="content">
            <h5 className="title">Deployment</h5>
            <ul>
              <li>Present a demo to client</li>
              <li>Deployment to the client</li>
              <li>After sales support</li>
            </ul>
            {/* <Link
              to={process.env.PUBLIC_URL + `/service`}
              className="more-btn"
            >
              Find out more
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePropTwo;
