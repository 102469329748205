import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

import greview from "../assets/image/Google_review.svg";
import gdreview from "../assets/image/Glassdoor_review.svg";
import creview from "../assets/image/Clutch_review.svg";
import seoData from "../seoData/Design.json"
// import argumentTeam from "../assets/image/services/argumentTeam.svg"
// import liIcon from "../li-icon.svg"

const Design = () => {
  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper StaffAugmentation">
        <HeaderOne />
        <BreadCrumbOne title="UI/UX Design" page="UI/UX Design" />

        <div className="section section-padding pb-0">
          <div className="container">
            {/* <div className="row">
              <p className="mb-3">
                Looking for the best mobile app development company that can
                help turn your business ideas Into successful Reality?
              </p>
              <p className="mb-3">
                For full-stack app development, hire our experienced mobile app
                developers. This will enhance your brand visibility and sales.
                We have designed and developed over 1200 Android and iOS-based
                mobile applications from scratch. Want to get your free quote?
              </p>
            </div> */}
            <section id="augmentation-card">
              {/* <div className="py-4">
                <div className="section-header">
                  <h5 className="mb-3">Web Application Development Company</h5>
                  <p>
                    As a leading web app development company, we leverage
                    long-standing experience in building and offering custom web
                    application development services to increase customer
                    satisfaction. We help you implement the right technologies
                    in areas that are best suited to bring your business value.
                  </p>
                  <p>
                    Our focus is to understand “What does the client want?” From
                    ideation to testing and delivery, our developers develop and
                    offer exceptional web app development services for
                    sustainable business growth.
                  </p>
                </div>
              </div> */}
              <div className="row">
                <div className="col-11 col-lg-9 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      1. Logo & Branding
                    </div>
                    <p className="mb-0 mt-3">
                      Logo design is the process of designing a logo. Branding
                      is the process of building a brand. To be more specific,
                      it is a strategy designed by companies to help people to
                      quickly identify their products and organization, and give
                      them a reason to choose their products over the
                      competition.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      2. Website Design
                    </div>
                    <p className="mb-0 mt-3">
                      Web designing is the process of planning, conceptualizing,
                      and implementing the plan for designing a website in a way
                      that is functional and offers a good user experience. User
                      experience is central to the web designing process.
                      Websites have an array of elements presented in ways that
                      make them easy to navigate.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      3. Mobile app design
                    </div>
                    <p className="mb-0 mt-3">
                      App design is an ongoing process comprising user
                      experience (UX) and user interface (UI) elements.
                      Designers ideate, define solutions, create the app's flow
                      and structure, and make stylistic choices from colors to
                      fonts. Designers base choices on user research and
                      feedback.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto me-lg-4 ms-lg-auto">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      4. Graphic/print design
                    </div>
                    <p className="mb-0 mt-3">
                      Print design, a subset of graphic design, is a form of
                      visual communication used to convey information to an
                      audience through intentional aesthetic design printed on a
                      tangible surface, designed to be printed on paper, as
                      opposed to presented on a digital platform.
                    </p>
                  </div>
                </div>
                <div className="col-11 col-lg-9 mt-4 mx-auto ms-lg-0">
                  <div className="augmentation-card">
                    <div className="augmentation-title num3">
                      5. Video production
                    </div>
                    <p className="mb-0 mt-3">
                      Video production is basically the entire process of
                      creating a video. Whether it's a short film, a full-length
                      movie, business marketing video, television commercial,
                      music video, or other type of film, the process may vary a
                      little with the specifics, but the overall process is
                      basically the same. The basic process can be broken down
                      into three subcategories.
                    </p>
                  </div>
                </div>
              </div>
              <div className="rate-row">
                <div
                  className="row align-items-center text-center aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <div className="col-12 col-lg-12">
                    <div className="review-h4">
                      <h4>Read our reviews on:</h4>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="review">
                      <img
                        src={greview}
                        className=" ls-is-cached lazyloaded"
                        alt="review"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="review review-border">
                      <img
                        src={gdreview}
                        className=" ls-is-cached lazyloaded"
                        alt="review"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="review">
                      <img
                        src={creview}
                        className=" ls-is-cached lazyloaded"
                        alt="review"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Design;
